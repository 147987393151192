import * as actionTypes from '../ActionTypes/ActionTypes'
import axios from 'axios'



export const setSiteConfig = (entity) => ({
    type: actionTypes.setSiteConfig,
    entity
})
export const updateSiteConfig = (entity) => ({
    type: actionTypes.updateSiteConfig,
    entity
})




export const getSiteConfigAsync = () => {

    return (dispatch) => {
       
        axios.get('/siteConfiguration/GetSiteConfigurations')
        .then((response) => {
            dispatch(setSiteConfig(response.data[0]))
        }).catch ((error) => {
        })
    }
}

export const updateSiteConfigAsync = (data, token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
     
        axios.post('/siteConfiguration/updateSiteConfigurations', data, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(setSiteConfig(response.data))
        }).catch ((error) => {
        })
    }
}