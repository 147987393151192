import * as actionTypes from './../ActionTypes/ActionTypes'
import axios from 'axios'
import { toast } from 'react-toastify';


export const addPoliticalParty = (entity) => ({
    type: actionTypes.addPoliticalParty,
    entity
})
export const setPoliticalParty = (entity) => ({
    type: actionTypes.setPoliticalParties,
    entity
})
export const removePoliticalParty = (id) => ({
    type: actionTypes.removePoliticalParty,
    id

})
export const getPoliticalParties = () => ({
    type: actionTypes.getPoliticalParties
})
export const startPoliticalParties = () => ({
    type: actionTypes.startPoliticalParty
})
export const stopPoliticalParties = () => ({
    type: actionTypes.stopPoliticalParty
})



export const addPoliticalPartiesAsync = (party, token) => {

    return (dispatch, getState) => { 
    
        const auth = `Bearer ${token}`
        dispatch(startPoliticalParties())
        axios.post('/politicalparty/AddPoliticalParty', party, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
           
            dispatch(stopPoliticalParties())
            dispatch(addPoliticalParty(response.data))
            toast.success('🦄 Political party added successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
      
        }).catch ((error) => {
            dispatch(stopPoliticalParties())
        })
    }
}
export const removePoliticalPartiesAsync = (id, token) => {

    return (dispatch) => {

        const auth = `Bearer ${token}`
        dispatch(startPoliticalParties())
        axios.delete('/politicalparty/RemovePoliticalParty/' + id, {
            headers: {
                Authorization: auth
            }
        } )
        .then((response) => {
            dispatch(stopPoliticalParties())
            dispatch(removePoliticalParty(response.data.party.politicalPartyId))
            toast.warn('🦄 Politial Party removed successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }).catch ((error) => {
            dispatch(stopPoliticalParties())
            dispatch(stopPoliticalParties())
        })
    }
}
export const getPoliticalPartiesAsync = () => {

    return (dispatch) => {
        dispatch(startPoliticalParties())
        axios.get('/politicalparty/GetPoliticalParties')
        .then((response) => {
            dispatch(stopPoliticalParties())
            dispatch(setPoliticalParty(response.data))
      
        }).catch ((error) => {
            dispatch(stopPoliticalParties())
        
        })
    }
}