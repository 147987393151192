import {
	createStore,
	applyMiddleware,
	combineReducers,
	// compose,
} from 'redux'
import thunk from 'redux-thunk'
import newReducer from './../Reducers/NewsReducer'
import chatReducer from './../Reducers/ChatReducer'
import politicalParty from './../Reducers/PoliticalPartyReducer'
import lg from './../Reducers/LgReducer'
import mp from './../Reducers/ManagementProfileReducer'
import pr from './../Reducers/PressReleaseReducer'
import timeTables from './../Reducers/ElectionTimeTableReducer'
import feedbacks from './../Reducers/feedbackReducer'
import electionObservers from './../Reducers/ElectionObserverReducer'
import adhoc from './../Reducers/RecruitmentAdhocReducer'
import permanent from './../Reducers/RecruitmentPermanentReducer'
import auth from './../Reducers/AuthReducer'
import config from './../Reducers/siteConfigReducer'
import accounts from './../Reducers/UsersReducer'
import election from './../Reducers/ElectionResultReducer'
import filter from './../Reducers/FilterReducer'
import trendingNews from './../Reducers/TrendingNewsReducer';

const reducers = combineReducers({
	newReducer,
	chatReducer,
	politicalParty,
	lg,
	mp,
	pr,
	timeTables,
	feedbacks,
	electionObservers,
	adhoc,
	permanent,
	auth,
	config,
	accounts,
	election,
	filter,
	trendingNews
})

//window devtool must be removed before production
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
	const store = createStore(reducers, applyMiddleware(thunk))

	return store
}
