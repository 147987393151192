import * as actionTypes from '../Actions/ActionTypes/ActionTypes'


const initialState = {
       feedbacks: [],
       isLoading: false,
       successFeedback: false
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.addFeedback:
            return {
                ...state,
                feedbacks: [...state.feedbacks, action.entity]
            }
        case actionTypes.setFeedbacks:
            return {
                ...state,
                feedbacks: [...action.entity]
            }
        case actionTypes.startFeedback:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.stopFeedback:
            return {
                ...state,
               isLoading: false
            }
        case actionTypes.successFeedback:
            return {
                ...state,
               successFeedback: true
            }
        case actionTypes.successStopFeedback:
            return {
                ...state,
               successFeedback: false
            }
        case actionTypes.removeFeedback:
            return {
                ...state,
                feedbacks: state.feedbacks.filter(x => x.feedbackId !== action.id)
            }
    
        default:
            return state;
    }
}