import React, { Component } from 'react';
import Layout from './Layout/Layout';
import Routes from './Routes/Routes'
import Navigation from './Navigation/Navigation'
import Footer from './Footer/Footer'
import { ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from './../Actions/ActionCreators'



class App extends Component {
  static displayName = App.name;

  componentDidMount(){
    this.props.onStartAuth()
  }

  render () {
    return (
      <Layout>
      <Navigation />
       <Routes/>
       <Footer/>
       <ToastContainer />
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onStartAuth: () => dispatch(actions.startAuthenticationValidity())
})


export default withRouter(connect(undefined, mapDispatchToProps)(App))
