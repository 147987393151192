import React, { Component } from 'react'
import NavMenu from './NavMenu/NavMenu'
import AdminNavMenu from './NavMenu/AdminNavMenu'
import Sidebar from './Sidebar/Sidebar'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

export class Navigation extends Component {

    state = {
        showSidebar: false
    }

    handleShowSideBar = () => {
        this.setState({
            showSidebar: !this.state.showSidebar
        })
    }

    render() {
      
        let navbar = (
            <>
            <NavMenu clicked = { this.handleShowSideBar }/> 
               <Sidebar show = { this.state.showSidebar} clicked={ this.handleShowSideBar }/>
            </>
        )

        if (this.props.isAuthenticated && (this.props.location.pathname.includes('/admindashboard') || this.props.location.pathname.includes('/admin'))) {
            navbar = (
                <> 
                <AdminNavMenu />
                </>
            )
        }

        
        return (
            <div>
              {
                  navbar
              }
            </div>
        )
    }
}
const mapStateToProps = state => ({
    isAuthenticated: state.auth.token !== null,
    role: state.auth.role
})
export default withRouter(connect(mapStateToProps)(Navigation))
