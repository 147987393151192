import * as actionTypes from './../ActionTypes/ActionTypes'
import axios from 'axios'



export const addlg = (entity) => ({
    type: actionTypes.addLG,
    entity
})
export const setlg = (entity) => ({
    type: actionTypes.setLG,
    entity
})
// export const removePoliticalParty = (id) => ({
//     type: actionTypes.removePoliticalParty,
//     id

// })

export const addWard = (entity) =>({
    type: actionTypes.addWard,
    entity
})
export const getlg = () => ({
    type: actionTypes.getLG

})
export const startlg = () => ({
    type: actionTypes.startLG

})
export const stoplg = () => ({
    type: actionTypes.stopLG

})
export const removeWard = (wardId, localGovernmentId) => {
    
return {
    type: actionTypes.removeWard,
    id: wardId,
    lgId: localGovernmentId
}
}
export const wardAddedSucceded = () => ({
    type: actionTypes.addWardSucceded
})

export const wardAddedFailed = () => ({
    type: actionTypes.addWardFailed
})
export const wardAddedSuccededStop = () => ({
    type: actionTypes.addWardSuccededStop
})

export const wardAddedFailedStop = () => ({
    type: actionTypes.addWardFailedStop
})



export const getlgAsync = () => {

    return (dispatch) => {
     dispatch(startlg())

        axios.get('/politicalparty/GetLocalGovernments')
        .then((response) => {

            dispatch(setlg(response.data))
            dispatch(stoplg())
      
        }).catch ((error) => {
            
            dispatch(stoplg())
        })
    }
}
export const addLGAsync = (lg, token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        axios.post('/PoliticalParty/AddLocalGovernment', lg, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {

            dispatch(setlg(response.data))
      
        }).catch ((error) => {
            
        
        })
    }
}
export const addWardAsync = (lg, token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        axios.post('/PoliticalParty/AddWardToLocalGovernment', lg, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(wardAddedSucceded())
            dispatch(addWard(response.data))
            setInterval(() => {
                dispatch(wardAddedSuccededStop())
            }, 2000)
        }).catch ((error) => {
            dispatch(wardAddedFailed())
            setInterval(() => {
                dispatch(wardAddedFailedStop())
            }, 2000)
        })
    }
}
export const addPollingUnitAsync = (lg, token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        axios.post('/PoliticalParty/AddPollingUnitToWard', lg, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
        }).catch ((error) => {
            
        })
    }
} 


export const deleteWardAsync = (ward, token) => {
    
    return dispatch => {
        const auth = `Bearer ${token}`
        axios.delete('/PoliticalParty/RemoveWard/' + ward.wardId, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(removeWard(response.data.eo.wardId, response.data.eo.localGovernmentId))
            
        }).catch ((error) => {
            
        })
    }
}



export const deletePollingUnitAsync = (poll, token) => {
    
    return dispatch => {
        const auth = `Bearer ${token}`
        axios.delete('/PoliticalParty/RemovePollingUnit/' + poll.pollingUnitId, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
           
            
        }).catch ((error) => {
            
        })
    }
}