import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import Spinner from '../Spinner/Spinner'
import { connect } from 'react-redux'

// import AdminDashBoard from '../Admin/AdminDashBoard'
// import Users from '../Admin/Users'




const Home = lazy(() => import('../Home/Home'))
const Election = lazy(() => import('../Election/Election'))
const ElectionObservers = lazy(() => import('../Election/ElectionObservers/ElectionObservers'))
const ElectionResults = lazy(() => import('../Election/ElectionResults/ElectionResults'))
const PublicChairmanship = lazy(() => import('../Election/ElectionResults/PublichChairmanship'))
const PublicCounsellorship = lazy(() => import('../Election/ElectionResults/PublicCounsellorship'))
const ElectionTimeTable = lazy(() => import('../Election/ElectionTimeTable/ElectionTimeTable'))
const ElectionWards = lazy(() => import('../Election/ElectionWards/ElectionWards'))
const VoterEducation = lazy(() => import('../Election/VoterEducation/VoterEducation'))
const VotingProcedure = lazy(() => import('../Election/VotingProcedure/VotingProcedure'))
const VotingGuideline = lazy(() => import('../Election/VotingGuideline/VotingGuideline'))
const Nomination = lazy(() => import('../Election/Nomination/Nomination'))




const NewsAndMedia = lazy(() => import('../NewsAndMedia/NewsAndMedia'))
const MediaAccreditation = lazy(() => import('../NewsAndMedia/MediaAccreditation/MediaAccreditation'))
const NewsLetter = lazy(() => import('../NewsAndMedia/NewsLetter/NewsLetter'))
const PressRelease = lazy(() => import('../NewsAndMedia/PressRelease/PressRelease'))
const PastChairman = lazy(() => import('../NewsAndMedia/PastChairman/PastChairman'))
const SupportCentre = lazy(() => import('../NewsAndMedia/SupportCentre/SupportCentre'))


const Employment = lazy(() => import('../Employment/Employment'))
const AdhocEmployment = lazy(() => import('../Employment/AdhocEmployment/AdhocEmployment'))
const PermanentEmployment = lazy(() => import('../Employment/PermanentEmployment/PermanentEmployment'))


const ContactUs = lazy(() => import('../ContactUs/ContactUs'))
const Faq = lazy(() => import('../Faq/Faq'))
const PoliticalParty = lazy(() => import('../PoliticalParty/PoliticalParty'))


const Commission = lazy(() => import('../AboutUs/Commission/Commission'));
const AboutUs   = lazy(() => import('../AboutUs/AboutUs'))
const Chairman  = lazy(() => import('../AboutUs/Chairman/Chairman'))
const Commissioner = lazy(() => import('../AboutUs/Commissioner/Commisioner'))
const PermanentSecretary = lazy(() => import('../AboutUs/PermanentSecretary/PermanentSecretary'))
const PrincipalOfficers = lazy(() => import('../AboutUs/PrincipalOffcers/PrincipalOfficers'))


// admin section starts here
const AdminDashBoardAdmin = lazy(() => import('../Admin/AdminDashBoard'))
const ElectionTimeTableAdmin = lazy(() => import('../Admin/ElectionTimeTable'))
const EmploymentAdminAdhoc = lazy(() => import('../Admin/EmploymentAdhoc'))
const EmploymentAdminPermanent = lazy(() => import('../Admin/EmploymentPermanent'))
const LocalGovernmentAdmin = lazy(() => import('../Admin/LocalGovernment'))
const ManagementAdmin = lazy(() => import('../Admin/Management'))
const ObserversAdmin = lazy(() => import('../Admin/Observers'))
const PartiesAdmin = lazy(() => import('../Admin/Parties'))
const PressReleaseAdmin = lazy(() => import('../Admin/PressRelease'))
const ResultsAdmin = lazy(() => import('../Admin/Results'))
const UsersAdmin = lazy(() => import('../Admin/Users'))
const PoliticalPartyAdmin = lazy(() => import('../Admin/PoliticalParty'))
const configuration = lazy(() => import('../Admin/Configuration'))
const Login = lazy(() => import('../Admin/Login'))
const Logout = lazy(() => import('../Admin/Logout'))
const Feedback = lazy(() => import('../Admin/Feedback'))
const AddChairmanship = lazy(() => import('../Admin/AddChairmanship'))
const EditChairmanship = lazy(() => import('../Admin/EditChairmanship'))
const ViewChairmanship = lazy(() => import('../Admin/ViewChairmanship'))
const AddCounsellorship = lazy(() => import('../Admin/AddCounsellorship'))
const EditCounsellorship = lazy(() => import('../Admin/EditCounsellorship'))
const ViewCounsellorship = lazy(() => import('../Admin/ViewCounsellorship'))
const ElectionResult = lazy(() => import('../Admin/electionScore'))
const ElectionResultCounsellor = lazy(() => import('../Admin/electionScoreCounsellor'))
const TrendingNews = lazy(() => import('../Admin/TrendingNews'))
const AddTrendingNews = lazy(() => import('../Admin/AddTrendingNews'))
const TrendingNewsDetail = lazy(() => import('../Admin/TrendingNewsDetail'))
const ChangeAdhocPositionLGA = lazy(() => import('../Admin/ChangeAdhocPositionLGA'))
const ManageEmail = lazy(() => import('../Admin/ManageEmail'))
const ManageSms = lazy(() => import('../Admin/ManageSms'))
//admin section ends here


const sp = (
    <div style={{width:'10%', margin:'300px auto', height: '70vh'}}>
        <Spinner />
    </div>
)
class Routes extends React.Component {


    render() {

        let  routes = (
            <Suspense fallback={sp}>
            <Switch>
             <Route exact path='/' component={Home} />

             <Route exact  path='/aboutus' component={AboutUs} />
             <Route  path='/aboutus/commission' component={Commission} />
             <Route  path='/aboutus/chairman' component={Chairman} />
             <Route  path='/aboutus/commissioner' component={Commissioner} />
             <Route  path='/aboutus/permanentsecretary' component={PermanentSecretary} />
             <Route  path='/aboutus/principalofficers' component={PrincipalOfficers} />

             <Route exact  path='/election' component={Election} />
             <Route  path='/election/electionobservers' component={ElectionObservers} />
             <Route  path='/election/electionresults' component={ElectionResults} />
             <Route  path='/election/chairmanshipresult' component={PublicChairmanship} />
             <Route  path='/election/counsellorshipresult' component={PublicCounsellorship} />
             <Route  path='/election/electiontimetable' component={ElectionTimeTable} />
             <Route  path='/election/electionwards' component={ElectionWards} />
             <Route  path='/election/votingprocedure' component={VotingProcedure} />
             <Route  path='/election/votereducation' component={VoterEducation} />
             <Route  path='/election/votingguideline' component={VotingGuideline} />
             <Route  path='/election/nomination' component={Nomination} />

             
             <Route exact  path='/newsandmedia' component={NewsAndMedia} />
             <Route  path='/newsandmedia/mediaaccreditation' component={MediaAccreditation} />
             <Route  path='/newsandmedia/newsletter' component={NewsLetter} />
             <Route  path='/newsandmedia/pastchairman' component={PastChairman} />
             <Route  path='/newsandmedia/pressrelease' component={PressRelease} />
             <Route  path='/newsandmedia/supportcentre' component={SupportCentre} />


             <Route exact  path='/employment' component={Employment} />
             <Route  path='/employment/adhocemployment' component={AdhocEmployment} />
             <Route  path='/employment/permanentemployment' component={PermanentEmployment} />



             <Route  path='/politicalparty' component={PoliticalParty} />
             <Route  path='/contactus' component={ContactUs} />
             <Route  path='/faq' component={Faq} />


             {/* Admin section of the application */}
             <Route  path='/admin/login' component={Login} />
             <Route path ='*' component ={Home} />

             </Switch>
        </Suspense>

        )


        if(this.props.isAuthenticated && this.props.role !== 'USER') {
            routes = (
                <Suspense fallback={sp}>
                <Switch>
                 {/* Admin section of the application */}
                 <Route  path='/admin/adminDashboard' component={AdminDashBoardAdmin} />
                 <Route  path='/admin/electionTimeTableAdmin' component={ElectionTimeTableAdmin} />
                 <Route  path='/admin/employmentAdminAdhoc' component={EmploymentAdminAdhoc} />
                 <Route  path='/admin/employmentAdminPermanent' component={EmploymentAdminPermanent} />
                 <Route  path='/admin/localGovernmentAdmin' component={LocalGovernmentAdmin} />
                 <Route  path='/admin/managementAdmin' component={ManagementAdmin} />
                 <Route  path='/admin/observersAdmin' component={ObserversAdmin} />
                 <Route  path='/admin/partiesAdmin' component={PartiesAdmin} />
                 <Route  path='/admin/pressReleaseAdmin' component={PressReleaseAdmin} />
                 <Route  path='/admin/resultsAdmin' component={ResultsAdmin} />
                 <Route  path='/admin/usersAdmin' component={UsersAdmin} />
                 <Route  path='/admin/politicalPartyAdmin' component={PoliticalPartyAdmin} />
                 <Route  path='/admin/configuration' component={configuration} />
                 <Route  path='/admin/login' component={Login} />
                 <Route  path='/admin/logout' component={Logout} />
                 <Route  path='/admin/feedback' component={Feedback} />


                 <Route path='/admin/addChairmanship' component={AddChairmanship} />
                 <Route path='/admin/viewChairmanship' component={ViewChairmanship} />
                 <Route path='/admin/editChairmanship/:id' component={EditChairmanship} />
                 <Route path='/admin/addCounsellorship' component={AddCounsellorship} />
                 <Route path='/admin/viewCounsellorship' component={ViewCounsellorship} />
                 <Route path='/admin/editCounsellorship/:id' component={EditCounsellorship} />
                 <Route path='/admin/electionScore/:id' component={ElectionResult} />
                 <Route path='/admin/electionScoreCounsellor/:id' component={ElectionResultCounsellor} />
                 <Route path='/admin/trendingNews' component={TrendingNews} />
                 <Route path='/admin/addTrendingNews' component={AddTrendingNews} />
                 <Route path='/admin/TrendingNewsDetail/:id' component={TrendingNewsDetail} />
                 <Route path='/admin/changeAdhocPositionLGA/:id' component={ChangeAdhocPositionLGA} />
                 <Route path='/admin/manageEmail' component={ManageEmail} />
                 <Route path='/admin/manageSms' component={ManageSms} />
    
                 </Switch>
            </Suspense>

            )
        }
        return (
            <>
            { routes }
            </>
        )
    }

    
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.token !== null,
    role: state.auth.role
})

export default connect(mapStateToProps)(Routes)
