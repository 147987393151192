import * as actionTypes from '../ActionTypes/ActionTypes'
import axios from 'axios'



export const setUsers = (entity) => ({
    type: actionTypes.setUsers,
    entity
})


export const removeUsers = (userId) => ({
    type: actionTypes.removeUsers,
    userId
})


export const addUsers = (entity) => ({
    type: actionTypes.addUsers,
    entity
})

export const stopUsers = () => ({
    type: actionTypes.stopUsers
})
export const startNewUser = () => ({
    type: actionTypes.isNewStart
})
export const stopNewUser = () => ({
    type: actionTypes.isNewStop
})
export const startUsers = () => ({
    type: actionTypes.startUsers
})


export const getAllUsersAsync = (token) => {
    return dispatch => {
        dispatch(startUsers())
        const auth = `Bearer ${token}`
        axios.get('/account/getusers', {
            headers: {
                Authorization: auth
            }
        }).then((response) => {
            dispatch(stopUsers())
            dispatch(setUsers(response.data))
        }).catch((error) => {
            dispatch(stopUsers())
        })
    }
}
export const addUserAsync = (data, token) => {
    return dispatch => {
        dispatch(startUsers())
        

        const auth = `Bearer ${token}`
        axios.post('/account/register',data, {
            headers: {
                Authorization: auth
            }
        }).then((response) => {
            dispatch(stopUsers())
            dispatch(startNewUser())
            dispatch(addUsers(response.data.userDetails))

            setTimeout(() => {
                dispatch(stopNewUser())
            }, 3000);
        }).catch((error) => {
            dispatch(stopUsers())
        })
    }
}
export const removeUserAsync = (id, token) => {
    return dispatch => {
        dispatch(startUsers())
        const auth = `Bearer ${token}`
        axios.delete('/account/remove/' + id, {
            headers: {
                Authorization: auth
            }
        }).then((response) => {
            dispatch(stopUsers())
            dispatch(removeUsers(response.data.userId))
        }).catch((error) => {
            dispatch(stopUsers())
        })
    }
}