import * as actionTypes from '../Actions/ActionTypes/ActionTypes'



const initialState = {
    text: '',
    position: '',
    gender: '',
    startdate: '',
    enddate: '',
    adhocbefore:'',
    status: '',
    lga: '',
    sortby: ''
}


export default (state = initialState, action ) => {
    switch(action.type) {
        case actionTypes.filterAdhocBefore: 
        return {
            ...state,
            adhocbefore: action.adhocbefore
        }
        case actionTypes.filterLga: 
        return {
            ...state,
            lga: action.lga
        }
        case actionTypes.filterPosition: 
        return {
            ...state,
            position: action.position
        }
        case actionTypes.filterGender: 
        return {
            ...state,
            gender: action.gender
        }
        case actionTypes.filterStartDate: 
        return {
            ...state,
            startdate: action.startdate
        }
        case actionTypes.filterEndDATE: 
        return {
            ...state,
            enddate: action.enddate
        }
        case actionTypes.filterStatus: 
        return {
            ...state,
            status: action.status
        }
        case actionTypes.filterText: 
        return {
            ...state,
            text: action.text
        }
        case actionTypes.sortBy: 
        return {
            ...state,
            sortby: action.sortby
        }
        default:
            return state;
    }
}