import * as actionTypes from '../Actions/ActionTypes/ActionTypes'

const initialState = {
    token: null,
    username: null,
    userId: null,
    role: null,
    error: null,
    isLoading: false
}


export default (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.loginSucceded:
            return {
                ...state,
                token: action.entity.token,
                username: action.entity.username,
                userId: action.entity.userId,
                role: action.entity.role,
                error: null
            }
        case actionTypes.logout:
            return {
                ...state,
                token: null,
                username: null,
                userId: null,
                role: null,
                error: null
            }
        case actionTypes.loginFailed:
            return {
                ...state,
                error: action.error
            }
        case actionTypes.authStart:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.authEnd:
            return {
                ...state,
                isLoading: false
            }
        default:
           return state;
    }
}