import * as actionTypes from './../Actions/ActionTypes/ActionTypes'


const initialState = {
       profiles: [],
       isLoading: false
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.addManagementProfile:
            return {
                ...state,
                profiles: [...state.profiles, action.entity]
            }
        case actionTypes.setManagementProfiles:
            return {
                ...state,
                profiles: action.entity
            }
        case actionTypes.startManagementProfile:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.editManagementProfile:

            return {
               ...state,
               profiles: state.profiles.map(x => {
                   if(x.managementProfileId === action.entity.managementProfileId) {
                       return {
                           ...x, ...action.entity
                       }
                   } else {
                       return x
                   }
               })
            }
        case actionTypes.editManagementProfilePicture:
            return {
               ...state,
               profiles: state.profiles.map(x => {
                   if(x.managementProfileId === action.entity.managementProfileId) {
                       return {
                           ...x, ...action.entity
                       }
                   } else {
                       return x
                   }
               })
            }
        case actionTypes.stopManagementProfile:
            return {
                ...state,
                isLoading: false
            }
    
        default:
            return state;
    }
}