import * as actionTypes from '../Actions/ActionTypes/ActionTypes'


const initialState = {
       pressReleases: [],
       isLoading: false
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.addPressRelease:
            return {
                ...state,
                pressReleases: [...state.pressReleases, action.entity]
            }
        case actionTypes.setPressReleases:
            return {
                ...state,
                pressReleases: action.entity
            }
        case actionTypes.startPressRelease:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.editPressRelease:
            return {
               ...state,
               pressReleases: state.pressReleases.map(x => {
                   if(x.pressReleaseId === action.entity.pressReleaseId) {
                       return {
                           ...x, ...action.entity
                       }
                   } else {
                       return x
                   }
               })
            }
        case actionTypes.archivePressRelease:
            return {
               ...state,
               pressReleases: state.pressReleases.map(x => {
                   if(x.pressReleaseId === action.entity.pressReleaseId) {
                       return {
                           ...x, status:2
                       }
                   } else {
                       return x
                   }
               })
            }
        case actionTypes.removePressRelease:
            return {
               ...state,
               pressReleases: state.pressReleases.filter(x => x.pressReleaseId !== action.entity.pressReleaseId)
               }
            
        case actionTypes.editPressReleasePicture:
            return {
               ...state,
               pressReleases: state.pressReleases.map(x => {
                   if(x.pressReleaseId === action.entity.pressReleaseId) {
                       return {
                           ...x, ...action.entity
                       }
                   } else {
                       return x
                   }
               })
            }
        case actionTypes.stopPressRelease:
            return {
                ...state,
                isLoading: false
            }
    
        default:
            return state;
    }
}