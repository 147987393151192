import * as actionTypes from '../Actions/ActionTypes/ActionTypes'


const initialState = {
       adhocs: [],
       isLoading: false,
       successAdhoc: false,
       current: {}
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.addRecruitmentAdhoc:
            return {
                ...state,
                adhocs: [...state.adhocs, action.entity]
            }
        case actionTypes.setcurrentRecruitmentAdhoc:
            return {
                ...state,
                current: action.entity
            }
        case actionTypes.setRecruitmentAdhocs:
            return {
                ...state,
                adhocs: action.entity
            }
        case actionTypes.editRecruitmentAdhoc:
            const updateDataPosition = state.adhocs.data.map(ob => {
                if(ob.recruitmentAdhocId === action.entity.recruitmentAdhocId) {
                    return {
                        ...action.entity
                    }
                } else {
                    return ob
                }
            })
            return {
                ...state,
                adhocs:  {...state.adhocs, data: updateDataPosition }
            }
            case actionTypes.ChangeRecruitmentAdhocStatus:
          const updateData = state.adhocs.data.map(ob => {
                if(ob.recruitmentAdhocId === action.entity.recruitmentAdhocId) {
                    return {
                        ...ob, status: action.entity.status
                    }
                } else {
                    return ob
                }
            })

            return {
                ...state,
               adhocs: {...state.adhocs, data: updateData }
            }
        case actionTypes.startRecruitmentAdhoc:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.stopRecruitmentAdhoc:
            return {
                ...state,
               isLoading: false
            }
        case actionTypes.successRecruitmentAdhoc:
            return {
                ...state,
                successAdhoc: true
            }
        case actionTypes.successStopRecruitmentAdhoc:
            return {
                ...state,
                successAdhoc: false
            }
        case actionTypes.removeRecruitmentAdhoc:

            return {
                ...state,
                adhocs: {...state.adhocs, data: state.adhocs.data.filter(x => x.recruitmentAdhocId !== action.id)}
            }
    
        default:
            return state;
    }
}