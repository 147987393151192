
import * as actionTypes from '../ActionTypes/ActionTypes'
import axios from 'axios'
import { toast } from 'react-toastify';



export const addNews = (entity) => ({
    type: actionTypes.addNews,
    entity
})
export const setAllNews = (entity) => ({
    type: actionTypes.getAllNews,
    entity
})
export const startNews = (entity) => ({
    type: actionTypes.startNews,
    entity
})
export const stopNews = (entity) => ({
    type: actionTypes.endNews,
    entity
})
export const setCurrentNews = (entity) => ({
    type: actionTypes.setCurrentNews,
    entity
})






export const addTrendingNewsAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/api/trendingNews', entity, {
				headers: {
                    'content-type': 'multipart/form-data',
					Authorization: auth,
				},
			})
			.then(response => {
				
				dispatch(addNews(response.data))
				toast.success('🦄 Trending News added successfully!', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch(error => {
                dispatch(stopNews())
            })
	}
}
export const gettingAllNewsAsync = (token) => {
	return dispatch => {
        dispatch(startNews())

		const auth = `Bearer ${token}`
		axios.get('/api/trendingNews', {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(stopNews())
				dispatch(setAllNews(response.data.data))
			})
			.catch(error => {
                dispatch(stopNews())
            })
	}
}

export const getNewsByIdAsync = (id, token) => {

	return dispatch => {
		dispatch(startNews())
		const auth = `Bearer ${token}`

		axios.get('/api/trendingNews/' + id , {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(stopNews())
				dispatch(setCurrentNews(response.data.data))
			})
			.catch(error => {
                dispatch(stopNews())
            })
	}
}


export const sendEmailOnlyAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/EmailSms/EmailOnlySender', entity, {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				toast.success('🦄 mail sent successfully!', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch(error => {
               
            })
	}
}
export const sendSmsOnlyAsync = (entity, token) => {

	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/EmailSms/EmailSmsOnlySender', entity, {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				toast.success('🦄 Sms sent successfully!', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch(error => {
               
            })
	}
}

