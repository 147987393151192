import * as actionTypes from './../Actions/ActionTypes/ActionTypes'


const initialState = {
    news: [],
    isLoading: false,
    current: {}
}


export default (state = initialState, action) => {
    
        switch (action.type) {
            case actionTypes.addNews:
                return {
                    ...state,
                    news: [ ...state.news, action.entity]
                }
            case actionTypes.getAllNews:
            return {
                ...state,
                news: [...action.entity]
            }
            case actionTypes.removeNews:
            return {
                ...state,
                news: state.news.filter(n => n.id !== action.id)
            }
            case actionTypes.updateNews:
            return {
                ...state,
                news: this.state.news.map(n => {
                    if(n.id !== action.entity.id)
                    {
                        return action.entity;
                    }
                    else 
                    {
                        return n;
                    }
                })
            }
            case actionTypes.startNews:
            return {
                ...state,
                isLoading: true
            }
            case actionTypes.endNews:
            return {
                ...state,
                isLoading: false
            }
            case actionTypes.setCurrentNews:
            return {
                ...state,
                current: action.entity
            }
            default:
                return state;
        }
        
}