import * as actionTypes from './../Actions/ActionTypes/ActionTypes'


const initialState = {
       localGovernments: [],
       isLoading: false,
       isWardAdded: false,
       isWardFailed: false
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.addLG:
            return {
                ...state,
                localGovernments: [...state.localGovernments, action.entity]
            }
        case actionTypes.setLG:
            return {
                ...state,
                localGovernments: action.entity
            }
        case actionTypes.addWard:
        const lg =  state.localGovernments.find(x => x.localGovernmentId === action.entity.localGovernmentId)
        const lgNew = {...lg, wards: [...lg.wards, action.entity]}
        const lgIndex = state.localGovernments.findIndex(x => x.localGovernmentId === lg.localGovernmentId)
        const updatedLg = [...state.localGovernments]
        updatedLg[lgIndex] = lgNew

        return {
            ...state,
            localGovernments: updatedLg
        }
        case actionTypes.startLG:
            return {
                ...state,
                isLoading: !state.isLoading
            }
        case actionTypes.addWardSucceded:
        return {
            ...state,
            isWardAdded: true
        }
        case actionTypes.addWardSuccededStop:
        return {
            ...state,
            isWardAdded: false
        }
        case actionTypes.addWardFailed:
        return {
            ...state,
            isWardFailed: true
        }
        case actionTypes.addWardFailedStop:
        return {
            ...state,
            isWardFailed: false
        }

        case actionTypes.removeWard:
        const mwlg =  state.localGovernments.find(x => x.localGovernmentId === action.lgId)
        const lgNewi = mwlg.wards.filter(x => x.wardId !== action.id)
        const lgIndex1 = state.localGovernments.findIndex(x => x.localGovernmentId === action.lgId)
        const newlgG = {...mwlg, wards: lgNewi}
        const updatedLg1 = [...state.localGovernments]
        updatedLg1[lgIndex1] = newlgG 
        return {
            ...state,
            localGovernments: updatedLg1
        }
        case actionTypes.stopLG:
            return {
                ...state,
                isLoading: !state.isLoading
            }
    
        default:
            return state;
    }
}