import * as actionTypes from '../Actions/ActionTypes/ActionTypes'

const initialState = {
    error: null,
    isLoading: false,
    users: [],
    isNew: false
}


export default (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.addUsers:
            return {
                ...state,
                users: [ ...state.users, action.entity]
            }
        case actionTypes.removeUsers:
            return {
                ...state,
               users: state.users.filter(user => user.userId !== action.userId)
            }
        case actionTypes.setUsers:
            return {
                ...state,
               users: action.entity
            }
        case actionTypes.startUsers:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.stopUsers:
            return {
                ...state,
                isLoading: false
            }
        case actionTypes.isNewStart:
            return {
                ...state,
                isNew: true
            }
        case actionTypes.isNewStop:
            return {
                ...state,
                isNew: false
            }
        default:
           return state;
    }
}