import * as actionTypes from '../ActionTypes/ActionTypes'
import axios from 'axios'
import { toast } from 'react-toastify';


export const addRecruitmentPermanent = (entity) => ({
    type: actionTypes.addRecruitmentPermanent,
    entity
})
export const setRecruitmentPermanents = (entity) => ({
    type: actionTypes.setRecruitmentPermanents,
    entity
})
export const removeRecruitmentPermanent = (id) => ({
    type: actionTypes.removeRecruitmentPermanent,
    id

})
export const changeStatus = (entity) => ({
    type: actionTypes.ChangeRecruitmentPermanentStatus,
    entity
})
export const getRecruitmentPermanents = () => ({
    type: actionTypes.getRecruitmentPermanents
})
export const startRecruitmentPermanent = () => ({
    type: actionTypes.startRecruitmentPermanent
})
export const stopRecruitmentPermanent = () => ({
    type: actionTypes.stopRecruitmentPermanent
})
export const successRecruitmentPermanent = () => ({
    type: actionTypes.successRecruitmentPermanent
})
export const successStopRecruitmentPermanent = () => ({
    type: actionTypes.successStopRecruitmentPermanent
})



export const addRecruitmentPermanentAsync = (fb) => {

    return (dispatch) => {
        dispatch(startRecruitmentPermanent())
        axios.post('/RecruitmentPermanent/AddRecruitmentPermanent', fb)
        .then((response) => {
           
            setTimeout(() => {
                 dispatch((successRecruitmentPermanent()))
                dispatch(stopRecruitmentPermanent())
            }, 3000);
            setTimeout(() => {
                dispatch(successStopRecruitmentPermanent())
            }, 10000);

            dispatch(addRecruitmentPermanent(response.data))
        }).catch ((error) => {

            dispatch(stopRecruitmentPermanent())
        })
    }
}

export const removeRecruitmentPermanentAsync = (id) => {

    return (dispatch) => {
        dispatch(startRecruitmentPermanent())
        axios.delete('/RecruitmentPermanent/removeRecruitmentPermanent/' + id)
        .then((response) => {
            dispatch(stopRecruitmentPermanent())
            dispatch(removeRecruitmentPermanent(response.data.party.politicalPartyId))
            toast.warn('🦄 Application removed successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }).catch ((error) => {
            dispatch(stopRecruitmentPermanent())
        })
    }
}

export const getRecruitmentPermanentAsync = () => {

    return (dispatch) => {
        dispatch(startRecruitmentPermanent())
        axios.get('/recruitmentPermanent/GetRecruitmentPermanents')
        .then((response) => {
            dispatch(stopRecruitmentPermanent())
            dispatch(setRecruitmentPermanents(response.data))
      
        }).catch ((error) => {
            dispatch(stopRecruitmentPermanent())
        
        })
    }
}

export const changeEmploymentPermanentStatusAsync = (id, status, token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        const req = {
                    recruitmentPermanentId:id,
                    status:status
                }
        dispatch(startRecruitmentPermanent())
        axios.post('/recruitmentPermanent/ChangeRecruitmentPermanentStatus', req, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(stopRecruitmentPermanent())
            dispatch(changeStatus(response.data.eo))
      
        }).catch ((error) => {
            dispatch(stopRecruitmentPermanent())
        
        })
    }
}