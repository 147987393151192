import * as actionTypes from './../Actions/ActionTypes/ActionTypes'


const initialState = {
       parties: [],
       isLoading: false
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.addPoliticalParty:
            return {
                ...state,
                parties: [...state.parties, action.entity]
            }
        case actionTypes.setPoliticalParties:
            return {
                ...state,
                parties: [...action.entity]
            }
        case actionTypes.startPoliticalParty:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.stopPoliticalParty:
            return {
                ...state,
               isLoading: false
            }
        case actionTypes.removePoliticalParty:
            return {
                ...state,
                parties: state.parties.filter(x => x.politicalPartyId !== action.id)
            }
    
        default:
            return state;
    }
}