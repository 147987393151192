import 'animate.css'
import 'aos/dist/aos.css'
import 'react-toastify/dist/ReactToastify.css';
import './Styles/Styles.scss'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux'
import CreateStore from './Store/store'
import axios from 'axios'
import ScrollToTop from './components/ScrollToTop/ScrollToTop';


// axios.defaults.baseURL = 'https://localhost:5001'
// axios.defaults.baseURL = 'http://novamarkscom-001-site5.btempurl.com'
axios.defaults.baseURL = 'https://lasiec.gov.ng'


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const store = CreateStore(); 



const app = (<Provider store= {store}>
  <BrowserRouter basename={baseUrl} forceRefresh={true}>
    <ScrollToTop/>
    <App />
   
  </BrowserRouter>
</Provider>)

ReactDOM.render( app
  ,
  rootElement);

registerServiceWorker();

