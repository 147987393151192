import * as actionTypes from '../ActionTypes/ActionTypes'
import axios from 'axios'
import { toast } from 'react-toastify';


export const addElectionTimeTable = (entity) => ({
    type: actionTypes.addElectionTimeTable,
    entity
})
export const setElectionTimeTable = (entity) => ({
    type: actionTypes.setElectionTimeTables,
    entity
})
export const removeElectionTimeTable = (id) => ({
    type: actionTypes.removeElectionTimeTable,
    id
})
export const getElectionTimeTables = () => ({
    type: actionTypes.getElectionTimeTables
})
export const startElectionTimeTables = () => ({
    type: actionTypes.startElectionTimeTable
})
export const stopElectionTimeTables = () => ({
    type: actionTypes.stopElectionTimeTable
})



export const addElectionTimeTableAsync = (party, token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        dispatch(startElectionTimeTables())
        axios.post('/ElectionTimeTable/AddElectionTimeTable', party, {
            headers: {
                authorization: auth
            }
        })
        .then((response) => {
            dispatch(stopElectionTimeTables())
            dispatch(addElectionTimeTable(response.data))
            toast.success('🦄 Election Time Table added successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
      
        }).catch ((error) => {
            dispatch(stopElectionTimeTables())
        })
    }
}
export const removeElectionTimeTableAsync = (id, token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        dispatch(startElectionTimeTables())
        axios.delete('/ElectionTimeTable/RemoveElectionTimeTable/' + id, {
            headers: {
                authorization: auth
            }
        })
        .then((response) => {
            dispatch(stopElectionTimeTables())
            dispatch(removeElectionTimeTable(response.data.table.electionTimeTableId))
            toast.warn('🦄 Election Time Table removed successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }).catch ((error) => {
            dispatch(stopElectionTimeTables())
            
        })
    }
}
export const getElectionTimeTablesAsync = () => {

    return (dispatch) => {
        dispatch(startElectionTimeTables())
        axios.get('/ElectionTimeTable/GetElectionTimeTables')
        .then((response) => {
            dispatch(stopElectionTimeTables())
            dispatch(setElectionTimeTable(response.data))
      
        }).catch ((error) => {
            dispatch(stopElectionTimeTables())
        
        })
    }
}