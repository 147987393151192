import React from 'react'

const year = new Date().getFullYear()

const Footer = () => {

    
    return (
        <footer className="footer">
        <div className="footer__inner">
            <div className="footer__lagosHistory">
                <h4>History of Lagos</h4>
                <p>
                    Lagos is the largest city and former capital of Nigeria and the largest megacity on the African continent 
                    in terms of population Approx. 21m (2016 est), It is also the 4th largest economy in Africa.
                </p>
                <button className="footerform__btn">Read More</button>
            </div>
            {/* <div className="footer__calender">
                <h4>Calender</h4>
                <div id="calender">
                <DatePicker autoFocus={true} selected={date} onChange={handlDateChange} inline/>
                </div>
            </div> */}
            <div className="footer__sub">
                <h4>Subscribe to our Newsletter</h4>
                <form className="footerform" onSubmit={e => {e.preventDefault()}}>
                    <div className="footerform__group">
                        <label>Email</label>
                        <input type="text" className="footerform__control" />
                    </div>

                    <button className="footerform__btn">Subscribe</button>
                </form>
            </div> 
            <div className="footer__lagosGovernment">
                <h4>Lagos State Government</h4>
                <p>
                    The Secretariat,<br />
                    Obafemi Awolowo way, Ikeja <br />
                    Lagos state, Nigeria.<br />

                    Email: info@lagosstate.gov.ng

                </p>
            </div>
        </div>
        <div className="container--footer">
        <p>&copy; <span>{year}</span> .  All rights reserved | Office of the Lagos State Independent Electoral Commission's Chairman   </p>
        </div>
    </footer>
    )
}

export default Footer
