import * as actionTypes from './../Actions/ActionTypes/ActionTypes'


const initialState = {
        isLoading: false
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.addSubscriber:
            return {
                ...state
            }
        case actionTypes.startSubscriber:
            return {
                ...state,
                isLoading:true
            }
        case actionTypes.endSubscriber:
            return {
                ...state,
                isLoading: false
            }
    
        default:
            return state;
    }
}