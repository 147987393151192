import * as actionTypes from './../ActionTypes/ActionTypes'
import axios from 'axios'
import { toast } from 'react-toastify';


export const addManagementProfile = (entity) => ({
    type: actionTypes.addManagementProfile,
    entity
})
export const editManagementProfile = (entity) => ({
    type: actionTypes.editManagementProfile,
    entity
})
export const editManagementProfilePicture = (entity) => ({
    type: actionTypes.editManagementProfilePicture,
    entity
})
export const setManagementProfile = (entity) => ({
    type: actionTypes.setManagementProfiles,
    entity
})

export const getManagementProfile = () => ({
    type: actionTypes.getManagementProfiles

})
export const startManagementProfile = () => ({
    type: actionTypes.startManagementProfile

})
export const stopManagementProfile = () => ({
    type: actionTypes.stopManagementProfile

})



export const getManagementProfileAsync = () => {

    return (dispatch) => {
     dispatch(startManagementProfile())
        axios.get('/ManagementProfile/GetAllProfiles')
        .then((response) => {

            dispatch(setManagementProfile(response.data))
            dispatch(stopManagementProfile())
      
        }).catch ((error) => {
            
            dispatch(stopManagementProfile())
        })
    }
}
export const addManagementAsync = (mp, token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        dispatch(startManagementProfile())
        axios.post('/ManagementProfile/AddPersonnel', mp, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(addManagementProfile(response.data))
            dispatch(stopManagementProfile())
      
        }).catch ((error) => {
            dispatch(stopManagementProfile())
        })
    }
}
export const editManagementProfileAsync = (mp, token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        dispatch(startManagementProfile())
        axios.post('/ManagementProfile/UpdatePersonnel', mp, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(editManagementProfile(response.data.man))
            dispatch(stopManagementProfile())
            toast.success('🦄 Profile Edited successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }).catch ((error) => {
            dispatch(stopManagementProfile())
        })
    }
}

export const editManagementProfilePictureAsync = (mp, token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        dispatch(startManagementProfile())
        axios.post('/ManagementProfile/UploadManagementProfilePicture', mp, {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(stopManagementProfile())
            dispatch(editManagementProfilePicture(response.data.man))
            toast.success('🦄 Profile Image uploaded successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }).catch ((error) => {
            dispatch(stopManagementProfile())
        })
    }
}