import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import lagos from './../../../Images/lagos.jpg'
import lasiec from './../../../Images/lasiec (1).jpg'
import SocialMedia from '../../SocialMedia/SocialMedia'
import { ReactComponent as MenuIcon } from "./../../../Svgs/menu-outline.svg";

const NavMenu = (props) =>  {
 

    return (
       <nav className="nav">
        <div className="nav__inner">
            <div className="nav__brand">
                <NavLink to="/home" className="nav__brand--lagos">
                    <img src={lagos} alt="LASIEC" />
                </NavLink>
                <NavLink to="/home" className="nav__brand--lasiec">
                    <img src={lasiec} alt="LASIEC" />
                </NavLink>
                <div className="nav__brand--text">
                  <h1>lasiec</h1>
                  <p className="first">Lagos state</p>
                  <p className="second">Independent Electoral Commission</p>
                </div>
            </div>
            <div className="nav__toggler-icon" onClick={props.clicked} >
             <MenuIcon />
            </div>
            <div className="nav__navigation">
                <ul className="nav__navigation--menu">
                    <li><NavLink exact to="/">Home</NavLink></li>
                    <li className="dropdown">
                        <NavLink to="/aboutus" className="dropdown__toggle">About us</NavLink>
                        <div className="dropdown__menu">
                        <Link className="dropdown__item" to="/aboutus/commission">Commission</Link>
                        <Link className="dropdown__item" to="/aboutus/chairman">The Chairman</Link>
                        <Link className="dropdown__item" to="/aboutus/commissioner">Commissioners</Link>
                        <Link className="dropdown__item" to="/aboutus/permanentsecretary">Permanent Secretary</Link>
                        <Link className="dropdown__item" to="/aboutus/principalofficers">Principal Officers</Link>
                        </div>
                    </li>
                    <li className="dropdown">
                        <NavLink to="/election" className="dropdown__toggle">Election</NavLink>
                        <div className="dropdown__menu">
                            {/* <Link className="dropdown__item" to="/election/votereducation">Voter Education</Link> */}
                            <Link className="dropdown__item" to="/election/votingguideline">Voting Guideline</Link>
                            <Link className="dropdown__item" to="/election/votingprocedure">Voting Procedure</Link>
                            <Link className="dropdown__item" to="/election/electionresults">Election Results</Link>
                            <Link className="dropdown__item" to="/election/electiontimetable">Election Time-Table</Link>
                            <Link className="dropdown__item" to="/election/electionwards">Election Wards</Link>
                            <Link className="dropdown__item" to="/election/electionobservers">Election Observers</Link>
                            <Link className="dropdown__item" to="/election/nomination">Nomination Forms</Link>
                        </div>
                    </li>
                    <li className="dropdown">
                        <NavLink to="/newsandmedia" className="dropdown__toggle">News/Media</NavLink>
                        <div className="dropdown__menu">
                            <Link className="dropdown__item" to="/newsandmedia/pressrelease">Press Release</Link>
                            {/* <Link className="dropdown__item" to="/newsandmedia/newsletter">News letter</Link> */}
                            {/* <Link className="dropdown__item" to="/newsandmedia/mediaaccreditation">Media Accreditation</Link> */}
                            {/* <Link className="dropdown__item" to="/newsandmedia/supportcentre">Support Centre</Link> */}
                            <Link className="dropdown__item" to="/newsandmedia/pastchairman">Past Chairman/Members</Link>
                        </div>
                    </li>
                    <li className="dropdown">
                        <NavLink to="/employment" className="dropdown__toggle">Career</NavLink>
                        <div className="dropdown__menu">
                            <Link className="dropdown__item" to="/employment/permanentemployment">Permanent Employment</Link>
                            <Link className="dropdown__item" to="/employment/adhocemployment">Adhoc Employment</Link>
                        </div>
                    </li>
                    <li><NavLink to="/politicalparty">Parties</NavLink></li>
                    <li><NavLink to="/contactus">Contact</NavLink></li>
                    <li><NavLink to="/faq">FAQ</NavLink></li>
                </ul>
                <ul className="nav__navigation--social">
                    <SocialMedia color="#fff"/>
                </ul>
            </div>
        </div>
        </nav>
    );
  
}


export default  NavMenu
