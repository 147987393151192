import * as actionTypes from './../ActionTypes/ActionTypes'



export const registerUser = (user) => {
    return {
        type: actionTypes.registerUser,
        user
    }
}

export const setConnection = (connection) => {
    return {
        type: actionTypes.setConnection,
        connection
    }
} 
export const setQueueInterval = (queue) => {
    return {
        type: actionTypes.setQueueInterval,
        queue
    }
} 
export const setRegistereduser = (username) => {
    return {
        type: actionTypes.setRegisteredUser,
        username
    }
} 
export const setCount = (count) => {
    return {
        type: actionTypes.setCount,
        count
    }
} 
export const setIsTyping = (typing) => {
    return {
        type: actionTypes.setIsTyping,
        typing
    }
} 
export const setAgentName = (agentName) => {
    return {
        type: actionTypes.setAgentName,
        agentName
    }
} 



export const setConnectionAsync = (connection, callback) => {

    return (dispatch) => {
      dispatch(setConnection(connection))
      setTimeout(() => {
          callback()
      }, 1000);
      
    }
}
export const registerUserAsync = (user) => {

    return (dispatch) => {
      dispatch(registerUser(user))
    }
}