import * as actionTypes from '../ActionTypes/ActionTypes'
import axios from 'axios'
import { toast } from 'react-toastify';


export const addPressRelease = (entity) => ({
    type: actionTypes.addPressRelease,
    entity
})
export const editPressRelease = (entity) => ({
    type: actionTypes.editPressRelease,
    entity
})
export const editPressReleasePicture = (entity) => ({
    type: actionTypes.editPressReleasePicture,
    entity
})
export const removePressRelease = (entity) => ({
    type: actionTypes.removePressRelease,
    entity
})
export const archivePressRelease = (entity) => ({
    type: actionTypes.archivePressRelease,
    entity
})
export const setPressRelease = (entity) => ({
    type: actionTypes.setPressReleases,
    entity
})

export const getPressRelease = () => ({
    type: actionTypes.getPressReleases

})
export const startPressRelease = () => ({
    type: actionTypes.startPressRelease

})
export const stopPressRelease = () => ({
    type: actionTypes.stopPressRelease

})



export const getPressReleaseAsync = () => {

    return (dispatch) => {
     dispatch(startPressRelease())
        axios.get('/PressRelease/GetAllPressReleases')
        .then((response) => {

            dispatch(setPressRelease(response.data))
            dispatch(stopPressRelease())
      
        }).catch ((error) => {
            
            dispatch(stopPressRelease())
        })
    }
}
export const getPressReleaseWithArchiveAsync = (token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
     dispatch(startPressRelease())
        axios.get('/PressRelease/GetAllPressReleasesWithArchive', {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {

            dispatch(setPressRelease(response.data))
            dispatch(stopPressRelease())
      
        }).catch ((error) => {
            
            dispatch(stopPressRelease())
        })
    }
}
export const addPressReleaseAsync = (mp, token) => {

    return (dispatch) => {
        dispatch(startPressRelease())
        const auth = `Bearer ${token}`
        axios.post('/PressRelease/AddPressRelease', mp, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(addPressRelease(response.data))
           
            toast.success('🦄 Press Release successfully added!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                
                });

            
            dispatch(stopPressRelease())
      
        }).catch ((error) => {
            dispatch(stopPressRelease())
        })
    }
}
export const editPressReleaseAsync = (mp, token) => {

    return (dispatch) => {
        dispatch(startPressRelease())
        const auth = `Bearer ${token}`
        axios.put('/PressRelease/UpdatePressRelease/' + mp.pressReleaseId, mp, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(editPressRelease(response.data.man))
            dispatch(stopPressRelease())
            toast.success('🦄 Press Release edited successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                
                });
        }).catch ((error) => {
            dispatch(stopPressRelease())
        })
    }
}
export const editPressReleasePictureAsync = (mp) => {

    return (dispatch) => {
        dispatch(startPressRelease())
        axios.post('/PressRelease/UploadPressReleasePicture', mp)
        .then((response) => {
            dispatch(stopPressRelease())
            dispatch(editPressReleasePicture(response.data))
        }).catch ((error) => {
            dispatch(stopPressRelease())
        })
    }
}
export const archivePressReleaseAsync = (mp, token) => {

    return (dispatch) => {
        dispatch(startPressRelease())
        const auth = `Bearer ${token}`
        axios.put('/PressRelease/ArchivePressRelease', mp, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(stopPressRelease())
            dispatch(archivePressRelease(response.data))
        }).catch ((error) => {
            dispatch(stopPressRelease())
        })
    }
}
export const removePressReleaseAsync = (mp, token) => {

    return (dispatch) => {
        dispatch(startPressRelease())
        const auth = `Bearer ${token}`
        axios.delete('/PressRelease/removePressRelease/' + mp.pressReleaseId, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(stopPressRelease())
            dispatch(removePressRelease(response.data))
            toast.success('🦄 Press Release deleted successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                
                });
        }).catch ((error) => {
           
            dispatch(stopPressRelease())
        })
    }
}