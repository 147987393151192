import * as actionTypes from '../Actions/ActionTypes/ActionTypes'


const initialState = {
       permanents: [],
       isLoading: false,
       successPermanent: false
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.addRecruitmentPermanent:
            return {
                ...state,
                permanents: [...state.permanents, action.entity]
            }
        case actionTypes.setRecruitmentPermanents:
            return {
                ...state,
                permanents: [...action.entity]
            }
            case actionTypes.ChangeRecruitmentPermanentStatus:
            return {
                ...state,
               permanents: state.permanents.map(ob => {
                   if(ob.recruitmentPermanentId === action.entity.recruitmentPermanentId) {
                       return {
                           ...action.entity
                       }
                   } else {
                       return ob
                   }
               })
            }
        case actionTypes.startRecruitmentPermanent:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.stopRecruitmentPermanent:
            return {
                ...state,
               isLoading: false
            }
        case actionTypes.successRecruitmentPermanent:
            return {
                ...state,
                successPermanent: true
            }
        case actionTypes.successStopRecruitmentPermanent:
            return {
                ...state,
                successPermanent: false
            }
        case actionTypes.removeRecruitmentPermanent:
            return {
                ...state,
                permanents: state.permanents.filter(x => x.feedbackId !== action.id)
            }
    
        default:
            return state;
    }
}