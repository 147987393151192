import * as actionTypes from '../ActionTypes/ActionTypes'
import axios from 'axios'



export const loginSucceded = (entity) => ({
    type: actionTypes.loginSucceded,
    entity
})


export const loginFailed = (error) => ({
    type: actionTypes.loginFailed,
    error
})


export const loginStart = () => ({
    type: actionTypes.authStart
})

export const loginEnd = () => ({
    type: actionTypes.authEnd
})

export const logout = () => {
    localStorage.removeItem('userId')
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('role')
    localStorage.removeItem('expirationTime')
    return {
        type: actionTypes.logout
    }
}

export const checkValidDateTime = (expiresInTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout())
        }, expiresInTime * 1000);
    }
}

export const loginAsync = (entity) => {
    return (dispatch) => {
        dispatch(loginStart())
        axios.post('/account/login', entity)
        .then(response => {
            const authData = {
                userId: response.data.userDetails.userId,
                token: response.data.token,
                username: response.data.userDetails.username,
                role: response.data.userDetails.department
            }
            const expireTime = new Date(Date.now() + response.data.expiresOn * 1000)

            localStorage.setItem('userId', response.data.userDetails.userId)
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('username', response.data.userDetails.username)
            localStorage.setItem('role', response.data.userDetails.department)
            localStorage.setItem('expirationTime', expireTime)

            setTimeout(() => {
                dispatch(loginEnd())
                dispatch(loginSucceded(authData))
                dispatch(checkValidDateTime(response.data.expiresOn))
            }, 3000);

            
            
        })
        .catch(error => {
            dispatch(loginEnd())
            dispatch(loginFailed(error))
           
        })
    }
}



export const startAuthenticationValidity = () => {
    return dispatch => {
        const token = localStorage.getItem('token')

        if(!token) {
            dispatch(logout())
        } else {
            const userId = localStorage.getItem('userId')
            const username = localStorage.getItem('username')
            const role = localStorage.getItem('role')
            const expirationTime = new Date(localStorage.getItem('expirationTime'))
            const authData = {
                userId,
                token,
                username,
                role
            }

            const diff = expirationTime.getTime() - Date.now()
            if(diff > 0) {
                dispatch(loginSucceded(authData))
                dispatch(checkValidDateTime(diff/1000))
            } else {
                dispatch(logout())
            }
        }
    }
}