import * as actionTypes from '../Actions/ActionTypes/ActionTypes'


const initialState = {
       tables: [],
       isLoading: false
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.addElectionTimeTable:
            return {
                ...state,
                tables: [...state.tables, action.entity]
            }
        case actionTypes.setElectionTimeTables:
            return {
                ...state,
                tables: [...action.entity]
            }
        case actionTypes.startElectionTimeTable:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.stopElectionTimeTable:
            return {
                ...state,
               isLoading: false
            }
        case actionTypes.removeElectionTimeTable:
            return {
                ...state,
                tables: state.tables.filter(x => x.electionTimeTableId !== action.id)
            }
    
        default:
            return state;
    }
}