import * as actionTypes from '../ActionTypes/ActionTypes'
import axios from 'axios'
import { toast } from 'react-toastify';


export const addFeedback = (entity) => ({
    type: actionTypes.addFeedback,
    entity
})
export const setFeedback = (entity) => ({
    type: actionTypes.setFeedbacks,
    entity
})
export const removeFeedback = (id) => ({
    type: actionTypes.removeFeedback,
    id

})
export const getFeedbacks = () => ({
    type: actionTypes.getFeedbacks
})
export const startFeedback = () => ({
    type: actionTypes.startFeedback
})
export const stopFeedback = () => ({
    type: actionTypes.stopFeedback
})
export const successFeedback = () => ({
    type: actionTypes.successFeedback
})
export const successStopFeedback = () => ({
    type: actionTypes.successStopFeedback
})



export const addFeedbackAsync = (fb) => {
    return (dispatch) => {
        dispatch(startFeedback())
        axios.post('/Feedback/AddFeedback', fb)
        .then((response) => {
            setTimeout(() => {
                 dispatch(successFeedback())
                dispatch(stopFeedback())
            }, 3000);
            setTimeout(() => {
                 dispatch(successStopFeedback())
            }, 9000);
            dispatch(addFeedback(response.data))
        }).catch ((error) => {
            dispatch(stopFeedback())
        })
    }
}

export const removeFeedbackAsync = (id) => {

    return (dispatch) => {
        dispatch(startFeedback())
        axios.delete('/feedback/removefeedback/' + id)
        .then((response) => {
            dispatch(stopFeedback())
            dispatch(removeFeedback(response.data.party.politicalPartyId))
            toast.warn('🦄 Politial Party removed successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }).catch ((error) => {
            dispatch(stopFeedback())
          
        })
    }
}
export const getFeedbackAsync = (token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        dispatch(startFeedback())
        axios.get('/feedback/getfeedbacks', {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(stopFeedback())
            dispatch(setFeedback(response.data))
      
        }).catch ((error) => {
            dispatch(stopFeedback())
        
        })
    }
}