import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavItem } from 'reactstrap';

import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {Link} from 'react-router-dom'


const SocialMedia = (props) => {
    return (
      
            <div className="social">
                 <NavItem>
                  <a tag={Link} className="text-dark social__facebook" rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/followlasiec/">
                    <FontAwesomeIcon  icon={faFacebookF}  style ={{color: props.color, width:'20'}} />
                  </a>
                </NavItem>
                 <NavItem>
                  <a tag={Link} target="_blank" rel="noopener noreferrer" className="text-dark social__twitter" href="https://www.twitter.com/follow@lasiec/">
                    <FontAwesomeIcon  icon={faTwitter} style ={{color: props.color}} />
                  </a>
                </NavItem>
                 <NavItem>
                  <a tag={Link} target="_blank" rel="noopener noreferrer" className="text-dark social__instagram" href="https://www.instagram.com/followlasiec/">
                    <FontAwesomeIcon  icon={faInstagram} style ={{color: 'props.color', width: '20'}} />
                  </a>
                </NavItem>
                 {/* <NavItem>
                  <NavLink tag={Link} className="text-dark social__youtube" to="/fetch-data">
                    <FontAwesomeIcon  icon={faYoutube} style ={{color: props.color}} />
                  </NavLink>
                </NavItem> */}
            </div>
     
    )
}

export default SocialMedia
