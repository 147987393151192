import * as actionTypes from './../ActionTypes/ActionTypes'

export const addFilterText = (text) => {
    return {
        type: actionTypes.filterText,
       text
    }
}

export const addFilterLga = (lga) => {
    return {
        type: actionTypes.filterLga,
       lga
    }
}

export const addFilterStatus = (status) => {
    return {
        type: actionTypes.filterStatus,
       status
    }
}
export const addFilterPosition = (position) => {
    return {
        type: actionTypes.filterPosition,
       position
    }
}
export const addFilterGender = (gender) => {
    return {
        type: actionTypes.filterGender,
       gender
    }
}

export const addFilterStartDate = (startdate) => {
    return {
        type: actionTypes.filterStartDate,
       startdate
    }
}

export const addFilterEndDate = (enddate) => {
    return {
        type: actionTypes.filterEndDATE,
       enddate
    }
}
export const addFilterAdhocBefore = (adhocbefore) => {
    return {
        type: actionTypes.filterAdhocBefore,
       adhocbefore
    }
}

export const addSortBy = (sortby) => {
    return {
        type: actionTypes.sortBy,
       sortby
    }
}
