import * as actionTypes from '../Actions/ActionTypes/ActionTypes'


const initialState = {
       observers: [],
       isLoading: false,
       successElectionObserver: false
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.addElectionObserver:
            return {
                ...state,
                observers: [...state.observers, action.entity]
            }
        case actionTypes.setElectionObservers:
            return {
                ...state,
                observers: [...action.entity]
            }
        case actionTypes.startElectionObserver:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.ChangeElectionObserverStatus:
     
            return {
                ...state,
               observers: state.observers.map(ob => {
                   if(ob.electionObserverId === action.entity.electionObserverId) {
                       return {
                           ...action.entity
                       }
                   } else {
                       return ob
                   }
               })
            }
        case actionTypes.stopElectionObserver:
            return {
                ...state,
               isLoading: false
            }
        case actionTypes.successElectionObserver:
            return {
                ...state,
                successElectionObserver: true
            }
        case actionTypes.resetsuccessElectionObserver:
            return {
                ...state,
                successElectionObserver: false
            }
        case actionTypes.removeElectionObserver:
            return {
                ...state,
                observers: state.observers.filter(x => x.electionObserverId !== action.id)
            }
    
        default:
            return state;
    }
}