import * as actionTypes from '../Actions/ActionTypes/ActionTypes'


const initialState = {
       config: {}
}


export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.setSiteConfig:
            return {
                ...state,
                config: {
                    ...action.entity
                }
            }
        case actionTypes.updateSiteConfig:
            return {...state,
            config: {
                ...action.entity
            }}
        default:
            return state;
    }
}