import * as actionTypes from '../Actions/ActionTypes/ActionTypes'

const initialState = {
	chairmen: [],
	lg: {},
	currentChairman: {},
	currentCounsellor: {},
	ward: {},
	isSuccess: false,
	counsellors: [],
	lgId: '',
	publish: [],
	counsellorPublish: []
}

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.addChairmanship:
			return {
				...state,
				chairmen: [...state.chairmen, action.entity],
			}
		case actionTypes.addCounsellorship:
			return {
				...state,
				counsellors: [...state.counsellors, action.entity],
			}
		case actionTypes.setChairmanship:
			return {
				...state,
				chairmen: action.entities,
				publish: []
			}
		case actionTypes.getSingleChairman: 
		return {
			...state,
			currentChairman: action.entity
		}
		case actionTypes.getSingleCounsellor: 
		return {
			...state,
			currentCounsellor: action.entity
		}
		case actionTypes.setCounsellorship:
			return {
				...state,
				counsellors: action.entities,
				counsellorPublish: []
			}
		case actionTypes.setCurrentLg:
			return {
				...state,
				lg: action.lg,
			}
		case actionTypes.winnerChairmain:
				const updatedChairman1 = state.chairmen.map(ch => {
					if (
						ch.chairmanshipElectionId ===
						action.entity.chairmanshipElectionId
					) {
						return action.entity
					} else {
						return ch
					}
				})
				return {
					...state,
					chairmen: updatedChairman1,
				}
		case actionTypes.winnerCounsellor:
				const updatedChairman19 = state.counsellors.map(ch => {
					if (
						ch.counsellorshipElectionId ===
						action.entity.counsellorshipElectionId
					) {
						return action.entity
					} else {
						return ch
					}
				})
				return {
					...state,
					counsellors: updatedChairman19,
				}
		case actionTypes.removeChairmain:
				const updatedChairman2 = state.chairmen.filter(ch => ch.chairmanshipElectionId !==action.entity.chairmanshipElectionId)
				return {
					...state,
					chairmen: updatedChairman2,
				}
		case actionTypes.removeCounsellor:
				const updatedChairman3 = state.counsellors.filter(ch => ch.counsellorshipElectionId !==action.entity.counsellorshipElectionId)
				return {
					...state,
					counsellors: updatedChairman3,
				}
		case actionTypes.editChairmanship:
			const updatedChairman = state.chairmen.map(ch => {
				if (
					ch.chairmanshipElectionId ===
					action.entity.chairmanshipElectionId
				) {
					return action.entity
				} else {
					return ch
				}
			})
			return {
				...state,
				chairmen: updatedChairman,
			}
		case actionTypes.editCounsellorship:
			const updatedCounsellor = state.counsellors.map(ch => {
				if (
					ch.counsellorshipElectionId ===
					action.entity.counsellorshipElectionId
				) {
					return action.entity
				} else {
					return ch
				}
			})

			return {
				...state,
				counsellors: updatedCounsellor,
			}
		case actionTypes.AddScoreToChairman:
			const revisedUpdated = state.chairmen.map(ch => {
				if (
					ch.chairmanshipElectionId ===
					action.score.chairmanshipElectionId
				) {
					return {
						...ch,
						electionRecords: action.score,
					}
				} else {
					return ch
				}
			})
			return {
				...state,
				chairman: revisedUpdated,
			}
		case actionTypes.AddScoreToCounsellor:
			const revisedUpdate = state.counsellors.map(ch => {
				if (
					ch.counsellorshipElectionId ===
					action.score.counsellorshipElectionId
				) {
					return {
						...ch,
						electionRecordCounsellor: action.score,
					}
				} else {
					return ch
				}
			})
			return {
				...state,
				counsellors: revisedUpdate,
			}
		case actionTypes.setCurrentWard:
			return {
				...state,
				ward: action.ward,
			}
		case actionTypes.begin:
			return {
				...state,
				isSuccess: true,
			}
		case actionTypes.end:
			return {
				...state,
				isSuccess: false,
			}
			case actionTypes.setLgId: 
			return {
				...state,
				lgId: action.lgId
			}

			case actionTypes.AddPublish: 
			return {
				...state,
				publish: [ ...state.publish, action.id]
			}
			case actionTypes.RemovePublish: 
			return {
				...state,
				publish: state.publish.filter(x => x !== action.id)
			}
			case actionTypes.AddCounsellorPublish: 
			return {
				...state,
				counsellorPublish: [ ...state.counsellorPublish, action.id]
			}
			case actionTypes.RemoveCounsellorPublish: 
			return {
				...state,
				counsellorPublish: state.counsellorPublish.filter(x => x !== action.id)
			}

		default:
			return state
	}
}
