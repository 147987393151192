import * as actionTypes from './../ActionTypes/ActionTypes'
import axios from 'axios'
import { toast } from 'react-toastify';

export const addSubscriber = (entity) => ({
    type: actionTypes.addSubscriber,
    entity
})
export const startSubscriber = () => ({
    type: actionTypes.startSubscriber

})
export const endSubscriber = () => ({
    type: actionTypes.endSubscriber

})



export const addSubscriberAsync = (entity) => {

    return (dispatch) => {
        dispatch(startSubscriber())
        axios.post('news/addsubscriber', entity)

        .then(() => {

            //to be removed before publishing into production
            setTimeout(() => {
                 dispatch(endSubscriber())
            }, 1000)
           
            toast.success('🦄 You have successfully subscribed!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                
                });
      
        }).catch ((error) => {
            toast.warning('🦄 The email address already subscribed!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                
                });
            dispatch(endSubscriber())
        })
    }
}