import * as actionTypes from '../ActionTypes/ActionTypes';
import axios from 'axios';
import { toast } from 'react-toastify';

export const addRecruitmentAdhoc = entity => ({
	type: actionTypes.addRecruitmentAdhoc,
	entity,
});
export const editPosLGARecruitmentAdhoc = entity => ({
	type: actionTypes.editRecruitmentAdhoc,
	entity,
});
export const setRecruitmentAdhocs = entity => ({
	type: actionTypes.setRecruitmentAdhocs,
	entity,
});
export const removeRecruitmentAdhoc = id => ({
	type: actionTypes.removeRecruitmentAdhoc,
	id,
});
export const changeStatus = entity => ({
	type: actionTypes.ChangeRecruitmentAdhocStatus,
	entity,
});
export const getRecruitmentAdhocs = () => ({
	type: actionTypes.getRecruitmentAdhocs,
});
export const startRecruitmentAdhoc = () => ({
	type: actionTypes.startRecruitmentAdhoc,
});
export const stopRecruitmentAdhoc = () => ({
	type: actionTypes.stopRecruitmentAdhoc,
});
export const setCurrentRecruitmentAdhoc = entity => ({
	type: actionTypes.setcurrentRecruitmentAdhoc,
	entity
});

export const successRecruitmentAdhoc = () => ({
	type: actionTypes.successRecruitmentAdhoc,
});

export const successStopRecruitmentAdhoc = () => ({
	type: actionTypes.successStopRecruitmentAdhoc,
});

export const addRecruitmentAdhocAsync = fb => {
	return dispatch => {
		dispatch(startRecruitmentAdhoc());
		axios
			.post('/RecruitmentAdhoc/AddRecruitmentAdhoc', fb)
			.then(response => {
				setTimeout(() => {
					dispatch(successRecruitmentAdhoc());
					dispatch(stopRecruitmentAdhoc());
				}, 3000);
				setTimeout(() => {
					// dispatch(successStopRecruitmentAdhoc())
				}, 10000);

				dispatch(addRecruitmentAdhoc(response.data));
			})
			.catch(error => {
				dispatch(stopRecruitmentAdhoc());
			});
	};
};
export const removeRecruitmentAdhocAsync = (id, token) => {
	return dispatch => {
		dispatch(startRecruitmentAdhoc());
		const auth = `Bearer ${token}`;

		axios
			.delete('/RecruitmentAdhoc/removeRecruitmentAdhoc/' + id, {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(stopRecruitmentAdhoc());
				dispatch(
					removeRecruitmentAdhoc(id)
				);
				toast.warn('🦄 Application removed successfully!', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch(error => {
				dispatch(stopRecruitmentAdhoc());
			});
	};
};
export const SendEmailSmsAsync = (id, token) => {
	return dispatch => {
	
		const auth = `Bearer ${token}`;
		const req = {
			id
		}
		
		axios
			.post('/EmailSms/EmailSmsSender', req,  {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
		
				toast.success('🦄 Email and Sms sent successfully!', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch(error => {
			});
	};
};
export const getRecruitmentAdhocsAsync = (
	token,
	url,
	{
		adhocbefore,
		currentValueGender,
		currentValueLGA,
		currentValuePosition,
		currentValueSort,
		currentValueStatus,
		endDate,
		pageSize,
		searchTerm,
		startDate,
	} = {}
) => {
	let baseUrl = '/recruitmentAdhoc/GetRecruitmentAdhocs?';

	if (url) {
		url = url
			.replace('https://lasiec.gov.ng', '')
			.replace('https://localhost:5001', '');
		baseUrl = url;
	}

    if(pageSize){
        baseUrl = `${baseUrl}pageNumber=1&pageSize=${pageSize}`
    }

    if(currentValueGender) {
        baseUrl = `${baseUrl}&currentValueGender=${currentValueGender}`
    }
    if(currentValuePosition) {
        baseUrl = `${baseUrl}&currentValuePosition=${currentValuePosition}`
    }

    if(currentValueStatus) {
        baseUrl = `${baseUrl}&currentValueStatus=${currentValueStatus}`
    }
    if(adhocbefore) {
        baseUrl = `${baseUrl}&adhocbefore=${adhocbefore}`
    }
  
    if(currentValueLGA) {
        baseUrl = `${baseUrl}&currentValueLGA=${currentValueLGA}`
    }
  
    if(startDate) {
        baseUrl = `${baseUrl}&startDate=${startDate}`
    }
  
    if(endDate) {
        baseUrl = `${baseUrl}&endDate=${endDate}`
    }
  
    if(currentValueSort) {
        baseUrl = `${baseUrl}&currentValueSort=${currentValueSort}`
    }
    if(searchTerm) {
        baseUrl = `${baseUrl}&searchTerm=${searchTerm}`
    }
  



	return dispatch => {
		const auth = `Bearer ${token}`;
		dispatch(startRecruitmentAdhoc());
		axios
			.get(baseUrl, {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(stopRecruitmentAdhoc());
				dispatch(setRecruitmentAdhocs(response.data));
			})
			.catch(error => {
				dispatch(stopRecruitmentAdhoc());
			});
	};
};

export const changeEmploymentAdhocStatusAsync = (
	id,
	status,
	token
) => {
	return dispatch => {
		const auth = `Bearer ${token}`;
		const req = {
			recruitmentAdhocId: id,
			status: status,
		};
		dispatch(startRecruitmentAdhoc());
		axios
			.post('/recruitmentAdhoc/ChangeRecruitmentAdhocStatus', req, {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				toast.success('🦄 Applicant Status updated successfully!', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				dispatch(stopRecruitmentAdhoc());
				dispatch(changeStatus(response.data.eo));
			})
			.catch(error => {
				dispatch(stopRecruitmentAdhoc());
			});
	};
};
export const changeEmploymentAdhocPositionLGAAsync = (obj, token) => {

	return dispatch => {
		const auth = `Bearer ${token}`;
		const req = obj;
		dispatch(startRecruitmentAdhoc());
		axios
			.post('/recruitmentAdhoc/UpdateRecruitmentAdhoc', req, {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				
				toast.success('🦄 Application updated successfully!', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				dispatch(editPosLGARecruitmentAdhoc(response.data))	;
				dispatch(stopRecruitmentAdhoc());

			})
			.catch(error => {
				dispatch(stopRecruitmentAdhoc());
			});
	};
};
export const getRecruitmentAdhocById = (id, token) => {

	return dispatch => {
		const auth = `Bearer ${token}`;
		dispatch(startRecruitmentAdhoc());
		axios
			.get('/recruitmentAdhoc/getByIdRecruitmentAdhoc/' + id, {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(stopRecruitmentAdhoc());
				
				
				dispatch(setCurrentRecruitmentAdhoc(response.data))
			})
			.catch(error => {
				dispatch(stopRecruitmentAdhoc());
			});
	};
};
