import * as actionTypes from '../ActionTypes/ActionTypes'
import axios from 'axios'
import { toast } from 'react-toastify';


export const addElectionObserver = (entity) => ({
    type: actionTypes.addElectionObserver,
    entity
})
export const setElectionObserver = (entity) => ({
    type: actionTypes.setElectionObservers,
    entity
})
export const changeStatus = (entity) => ({
    type: actionTypes.ChangeElectionObserverStatus,
    entity
})
export const removeElectionObserver = (id) => ({
    type: actionTypes.removeElectionObserver,
    id

})
export const getElectionObservers = () => ({
    type: actionTypes.getElectionObservers
})
export const startElectionObserver = () => ({
    type: actionTypes.startElectionObserver
})
export const stopElectionObserver = () => ({
    type: actionTypes.stopElectionObserver
})
export const successElectionObserver = () => ({
    type: actionTypes.successElectionObserver
})
export const resetsuccessElectionObserver = () => ({
    type: actionTypes.resetsuccessElectionObserver
})



export const addElectionObserverAsync = (fb) => {

    return (dispatch) => {
        dispatch(startElectionObserver())
        axios.post('/ElectionObserver/AddElectionObserver', fb)
        .then((response) => {
           
            setTimeout(() => {
                 dispatch(successElectionObserver())
                dispatch(stopElectionObserver())
            }, 3000);

            dispatch(addElectionObserver(response.data))
            setTimeout(() => {
                dispatch(resetsuccessElectionObserver())
           }, 10000);

        }).catch ((error) => {

            dispatch(stopElectionObserver())
        })
    }
}
export const removeElectionObserverAsync = (id, token) => {

    return (dispatch) => {
        dispatch(startElectionObserver())
        const auth = `Bearer ${token}`
        axios.delete('/ElectionObserver/removeElectionObserver/' + id, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(stopElectionObserver())
            setTimeout(() => {
                
                dispatch(removeElectionObserver(response.data.eo.electionObserverId))
            }, 5000);
        
            toast.warn('🦄 Politial Party removed successfully!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }).catch ((error) => {
            dispatch(stopElectionObserver())
        })
    }
}

export const getElectionObserverAsync = (token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        dispatch(startElectionObserver())
        axios.get('/ElectionObserver/getElectionObservers', {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(stopElectionObserver())
            dispatch(setElectionObserver(response.data))
      
        }).catch ((error) => {
            dispatch(stopElectionObserver())
        
        })
    }
}
export const changeElectionObserverStatusAsync = (id, status, token) => {

    return (dispatch) => {
        const auth = `Bearer ${token}`
        const req = {
                    electionObserverId:id,
                    status:status
                }
            
    
        dispatch(startElectionObserver())
        axios.post('/ElectionObserver/ChangeElectionObserverStatus', req, {
            headers: {
                Authorization: auth
            }
        })
        .then((response) => {
            dispatch(stopElectionObserver())
            dispatch(changeStatus(response.data.eo))
      
        }).catch ((error) => {
            dispatch(stopElectionObserver())
        
        })
    }
}


