export const addSubscriber = 'ADD_SUBSCRIBER'
export const startSubscriber = 'START_SUBSCRIBER'
export const endSubscriber = 'END_SUBSCRIBER'


//chat user
export const setConnection = 'SET_CONNECTION'
export const setQueueInterval = 'SET_QUEUE_INTERVAL'
export const registerUser = 'REGISTER_USER'
export const sendMessage = 'SEND_MESSAGE'
export const closeChatBox = 'CLOSE_CHAT_BOX'


export const setRegisteredUser = 'SET_REGISTEREDUSER'
export const setCount = 'SET_COUNT'
export const setIsTyping = 'SET_IS_TYPING'
export const setAgentName ='SET_AGENT_NAME'



export const setPoliticalParties = 'SET_POLITICAL_PARTIES'
export const getPoliticalParties = 'GET_POLITICAL_PARTIES'
export const addPoliticalParty = 'ADD_POLITICAL_PARTY'
export const removePoliticalParty = 'REMOVE_POLITICAL_PARTY'
export const startPoliticalParty = 'START_POLITICAL_PARTY'
export const stopPoliticalParty = 'STOP_POLITICAL_PARTY'




export const setFeedbacks = 'SET_FEEDBACKS'
export const getFeedbacks = 'GET_FEEDBACKS'
export const addFeedback = 'ADD_FEEDBACK'
export const removeFeedback = 'REMOVE_FEEDBACK'
export const startFeedback = 'START_FEEDBACK'
export const stopFeedback = 'STOP_FEEDBACK'
export const successFeedback = 'SUCCESS_FEEDBACK'
export const successStopFeedback = 'SUCCESS_STOP_FEEDBACK'



export const setElectionObservers = 'SET_ELECTIONOBSERVERS'
export const getElectionObservers = 'GET_ELECTIONOBSERVERS'
export const addElectionObserver = 'ADD_ELECTIONOBSERVER'
export const ChangeElectionObserverStatus = 'CHANGE_ELECTIONOBSERVER_STATUS'
export const removeElectionObserver = 'REMOVE_ELECTIONOBSERVER'
export const startElectionObserver = 'START_ELECTIONOBSERVER'
export const stopElectionObserver = 'STOP_ELECTIONOBSERVER'
export const successElectionObserver = 'SUCCESS_ELECTIONOBSERVER'
export const resetsuccessElectionObserver = 'RESET_SUCCESS_ELECTIONOBSERVER'



export const setRecruitmentAdhocs = 'SET_RECRUITMENTADHOCS'
export const getRecruitmentAdhocs = 'GET_RECRUITMENTADHOCS'
export const addRecruitmentAdhoc = 'ADD_RECRUITMENTADHOC'
export const editRecruitmentAdhoc = 'EDIT_RECRUITMENTADHOC'
export const removeRecruitmentAdhoc = 'REMOVE_RECRUITMENTADHOC'
export const ChangeRecruitmentAdhocStatus = 'CHANGE_RECRUITMENTADHOC_STATUS'
export const startRecruitmentAdhoc = 'START_RECRUITMENTADHOC'
export const stopRecruitmentAdhoc = 'STOP_RECRUITMENTADHOC'
export const setcurrentRecruitmentAdhoc = 'SET_CURRENT_RECRUITMENTADHOC'
export const successRecruitmentAdhoc = 'SUCCESS_RECRUITMENTADHOC'
export const successStopRecruitmentAdhoc = 'SUCCESS_STOP_RECRUITMENTADHOC'



export const setRecruitmentPermanents = 'SET_RECRUITMENTPERMANENTS'
export const getRecruitmentPermanents = 'GET_RECRUITMENTPERMANENTS'
export const addRecruitmentPermanent = 'ADD_RECRUITMENTPERMANENT'
export const removeRecruitmentPermanent = 'REMOVE_RECRUITMENTPERMANENT'
export const ChangeRecruitmentPermanentStatus = 'CHANGE_RECRUITMENTPERMANENT_STATUS'
export const startRecruitmentPermanent = 'START_RECRUITMENTPERMANENT'
export const stopRecruitmentPermanent = 'STOP_RECRUITMENTPERMANENT'
export const successRecruitmentPermanent = 'SUCCESS_RECRUITMENTPERMANENT'
export const successStopRecruitmentPermanent = 'SUCCESS_STOP_RECRUITMENTPERMANENT'




export const setElectionTimeTables = 'SET_ELECTION_TIME_TABLES'
export const getElectionTimeTables = 'GET_ELECTION_TIME_TABLES'
export const addElectionTimeTable = 'ADD_ELECTION_TIME_TABLE'
export const removeElectionTimeTable = 'REMOVE_ELECTION_TIME_TABLE'
export const startElectionTimeTable = 'START_ELECTION_TIME_TABLE'
export const stopElectionTimeTable = 'STOP_ELECTION_TIME_TABLE'




export const addLG = 'ADD_LG'
export const setLG = 'SET_LG'
export const getLG = 'GET_LG'
export const startLG = 'START_LG'
export const stopLG = 'STOP_LG'
export const addWardSucceded = 'ADD_WARD_SUCCEDED'
export const addWardSuccededStop = 'ADD_WARD_SUCCEDED_STOP'
export const addWardFailed = 'ADD_WARD_FAILED'
export const addWardFailedStop = 'ADD_WARD_FAILED_STOP'
export const addWard = 'ADD_WARD'
export const removeWard = 'REMOVE_WARD'
export const addPollingUnitSucceded = 'ADD_POLLING_UNIT_SUCCEDED'
export const addPollingUnitFailed = 'ADD_POLLING_UNIT_FAILED'
export const addPollingUnit = 'ADD_POLLING_UNIT'



export const addManagementProfile = 'ADD_MANAGEMENT_PROFILE'
export const editManagementProfile = 'EDIT_MANAGEMENT_PROFILE'
export const editManagementProfilePicture = 'EDIT_MANAGEMENT_PROFILE_PICTURE'
export const setManagementProfiles = 'SET_MANAGEMENT_PROFILES'
export const getManagementProfiles = 'GET_MANAGEMENT_PROFILES'
export const startManagementProfile = 'START_MANAGEMENT_PROFILE'
export const stopManagementProfile = 'STOP_MANAGEMENT_PROFILE'


export const addPressRelease = 'ADD_PRESS_RELEASE'
export const editPressRelease = 'EDIT_PRESS_RELEASE'
export const archivePressRelease = 'ARCHIVE_PRESS_RELEASE'
export const removePressRelease = 'REMOVE_PRESS_RELEASE'
export const editPressReleasePicture = 'EDIT_PRESS_RELEASE_PICTURE'
export const setPressReleases = 'SET_PRESS_RELEASES'
export const getPressReleases = 'GET_PRESS_RELEASES'
export const startPressRelease = 'START_PRESS_RELEASE'
export const stopPressRelease = 'STOP_PRESS_RELEASE'

//authentication
export const loginSucceded = 'LOGIN_SUCCEDED'
export const loginFailed = 'LOGIN_FAILED'
export const logout = 'LOGOUT'
export const authStart = 'AUTH_START'
export const authEnd = 'AUTH_END'


//site configuration
export const setSiteConfig = 'GET_SITE_CONFIG'
export const updateSiteConfig = 'UPDATE_SITE_CONFIG'

//users
export const setUsers = 'SET_USERS'
export const startUsers = 'START_USERS'
export const stopUsers = 'STOP_USERS'
export const removeUsers = 'REMOVE_USERS'
export const addUsers = 'ADD_USERS'
export const isNewStart = 'IS_NEW_START'
export const isNewStop = 'IS_NEW_STOP'



//election Result section starts
export const addChairmanship = 'ADD_CHAIRMANSHIP'
export const editChairmanship = 'EDIT_CHAIRMANSHIP'
export const getSingleChairman = 'GET_SINGLE_CHAIRMAN'
export const getSingleCounsellor = 'GET_SINGLE_COUNSELLOR'
export const begin = 'BEGIN'
export const end = 'END'
export const setChairmanship = 'SET_CHAIRMANSHIP'
export const setCurrentLg = 'SET_CURRENT_LG'
export const setCurrentWard = 'SET_CURRENT_WARD'
export const AddScoreToChairman = 'ADD_SCORE_TO_CHAIRMAN'
export const winnerChairmain = 'WINNER_CHAIRMAN'
export const winnerCounsellor = 'WINNER_COUNSELLOR'
export const removeChairmain = 'REMOVE_CHAIRMAN'
export const removeCounsellor = 'REMOVE_COUNSELLOR'



export const addCounsellorship = 'ADD_COUNSELLORSHIP'
export const editCounsellorship = 'EDIT_COUNSELLORSHIP'
export const setCounsellorship = 'SET_COUNSELLORSHIP'
export const AddScoreToCounsellor = 'ADD_SCORE_TO_COUNSELLOR'

export const setLgId = 'SET_LG_ID'

//election result section ends



export const AddPublish = 'ADD_PUBLISH'
export const RemovePublish = 'REMOVE_PUBLISH'

export const AddCounsellorPublish = 'ADD_COUNSELLOR_PUBLISH'
export const RemoveCounsellorPublish = 'REMOVE_COUNSELLOR_PUBLISH'


export const filterText = 'ADD_FILTER_TEXT';
export const filterLga = 'ADD_FILTER_LGA';
export const filterGender = 'ADD_FILTER_GENDER';
export const filterStartDate = 'ADD_FILTER_START_DATE';
export const filterEndDATE = 'ADD_FILTER_END_DATE';
export const filterAdhocBefore = 'ADD_FILTER_ADHOC_BEFORE';
export const filterStatus = 'ADD_FILTER_STATUS';
export const filterPosition = 'ADD_FILTER_POSITION';
export const sortBy = 'ADD_SORT_BY'


export const startNews = 'START_NEWS';
export const endNews = 'END_NEWS';
export const addNews = 'ADD_NEWS';
export const removeNews = 'REMOVE_NEWS';
export const updateNews = 'UPDATE_NEWS';
export const getAllNews = 'GET_ALL_NEWS';
export const setCurrentNews = 'SET_CURRENT_NEWS'




