import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import lagos from './../../../Images/lagos.jpg'
import lasiec from './../../../Images/lasiec (1).jpg'
// import SocialMedia from '../../SocialMedia/SocialMedia'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavItem } from 'reactstrap'
// import { ReactComponent as MenuIcon } from "./../../../Svgs/menu-outline.svg";
import { ReactComponent as CloseIcon } from '../../../Svgs/close-outline.svg'
import {
	faFacebookF,
	faTwitter,
	faInstagram,
} from '@fortawesome/free-brands-svg-icons'

const Sidebar = props => {
	const sidebar = ['sidebar']

	if (props.show) {
		sidebar.push('showSidebar')
	} else {
		sidebar.push('hideSidebar')
	}

	const displaySubMenuHandler = event => {
		event.stopPropagation()
		event.preventDefault()
		const target = event.target
		target.classList.toggle('active')

		const panel = target.nextElementSibling

		if (panel.style.maxHeight) {
			panel.style.maxHeight = null
			panel.style.display = 'none'
		} else {
			panel.style.display = 'block'
			panel.style.maxHeight = panel.scrollHeight + 'px'
		}
	}

	return (
		<nav className={sidebar.join(' ')} onClick={props.clicked}>
			<span onClick={props.clicked}>
				<CloseIcon />
			</span>

			<div className='sidebar__inner'>
				<div className='sidebar__brand'>
					<div className='sidebar__brand__inner'>
						<Link to='/' className='sidebar__brand--lagos'>
							<img src={lagos} alt='LASIEC' />
						</Link>
						<div className='sidebar__brand--text'>
							<h1>lasiec</h1>
							<p className='first'>Lagos state</p>
							<p className='second'>
								Independent Electoral Commission
							</p>
						</div>
						<Link to='/' className='sidebar__brand--lasiec'>
							<img src={lasiec} alt='LASIEC' />
						</Link>
					</div>
				</div>

				<div className='sidebar__navigation'>
					<ul className='sidebar__navigation--menu'>
						<li className='active'>
							<Link to='/'>Home</Link>
						</li>
						<li className='sidebar__dropdown'>
							<NavLink
								to='/aboutus'
								onClick={displaySubMenuHandler}
								className='sidebar__dropdown__toggle accordion'>
								About us
							</NavLink>
							<div className='sidebar__dropdown__menu panel'>
								<Link
									className='sidebar__dropdown__item'
									to='/aboutus/commission'>
									Commission
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/aboutus/chairman'>
									The Chairman
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/aboutus/commissioner'>
									Commissioners
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/aboutus/permanentsecretary'>
									Permanent Secretary
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/aboutus/principalofficers'>
									Principal Officers
								</Link>
							</div>
						</li>
						<li className='sidebar__dropdown'>
							<NavLink
								to='/election'
								onClick={displaySubMenuHandler}
								className='sidebar__dropdown__toggle accordion'>
								Election
							</NavLink>
							<div className='sidebar__dropdown__menu panel'>
								<Link
									className='sidebar__dropdown__item'
									to='/election/votereducation'>
									Voter Education
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/election/votingguideline'>
									Voting Guideline
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/election/votingprocedure'>
									Voting Procedure
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/election/electionresults'>
									Election Results
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/election/electiontimetable'>
									Election Time-Table
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/election/electionwards'>
									Election Wards
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/election/electionobservers'>
									Election Observers
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/election/nomination'>
									Nomination Forms
								</Link>
							</div>
						</li>
						<li className='sidebar__dropdown'>
							<NavLink
								to='/newsandmedia'
								onClick={displaySubMenuHandler}
								className='sidebar__dropdown__toggle accordion'>
								News/Media
							</NavLink>

							<div className='sidebar__dropdown__menu panel'>
								<Link
									className='sidebar__dropdown__item'
									to='/newsandmedia/pressrelease'>
									Press Release
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/newsandmedia/newsletter'>
									News letter
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/newsandmedia/mediaaccreditation'>
									Media Accreditation
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/newsandmedia/supportcentre'>
									Support Centre
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/newsandmedia/pastchairman'>
									Past Chairman/Members
								</Link>
							</div>
						</li>
						<li className='sidebar__dropdown'>
							<NavLink
								to='/employment'
								onClick={displaySubMenuHandler}
								className='sidebar__dropdown__toggle accordion'>
								Career
							</NavLink>
							<div className='sidebar__dropdown__menu panel'>
								<Link
									className='sidebar__dropdown__item'
									to='/employment/permanentemployment'>
									Permanent Employment
								</Link>
								<Link
									className='sidebar__dropdown__item'
									to='/employment/adhocemployment'>
									Adhoc Employment
								</Link>
							</div>
						</li>
						<li>
							<NavLink to='/politicalparty'>Parties</NavLink>
						</li>
						<li>
							<NavLink to='/contactus'>Contact</NavLink>
						</li>
						<li>
							<NavLink to='/faq'>FAQ</NavLink>
						</li>
					</ul>
					<hr />
					<ul className='sidebar__navigation--social'>
						<NavItem>
							<Link
								tag={Link}
								className='text-dark social__facebook'
								to='/fetch-data'
								style={{
									backgroundColor: '#2A5BA2',
									padding: '10px',
								}}>
								<FontAwesomeIcon
									icon={faFacebookF}
									style={{
										color: 'white',
										width: '20px',
										height: '20px',
									}}
								/>
							</Link>
						</NavItem>
						<NavItem>
							<Link
								tag={Link}
								className='text-dark social__twitter'
								to='/fetch-data'
								style={{
									backgroundColor: '#05B1E7',
									padding: '10px',
								}}>
								<FontAwesomeIcon
									icon={faTwitter}
									style={{
										color: 'white',
										width: '20px',
										height: '20px',
									}}
								/>
							</Link>
						</NavItem>
						<NavItem>
							<NavLink
                                tag={Link}
                                style={{ display:'flex', justifyContent:'center', alignItems: 'center', width: '40px', height: '40px', marginTop: '6px'}}
								className='text-dark social__instagram'
								to='/fetch-data'>
								<FontAwesomeIcon
									icon={faInstagram}
									style={{ color: 'props.color', width: '20px', height: 'auto' }}
								/>
							</NavLink>
						</NavItem>
					</ul>
				</div>
			</div>
		</nav>
	)
}

export default Sidebar
