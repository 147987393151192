import React from 'react';
import { Link } from 'react-router-dom';
import lagos from './../../../Images/lagos.jpg'
import lasiec from './../../../Images/lasiec (1).jpg'
// import SocialMedia from '../../SocialMedia/SocialMedia'
import { ReactComponent as MenuIcon } from "./../../../Svgs/menu-outline.svg";
import { connect } from 'react-redux'


const NavMenu = (props) =>  {
 
    return (
        <nav className="nav">
        <div className="nav__inner">
            <div className="nav__brand">
                <Link to="/home" className="nav__brand--lagos">
                    <img src={lagos} alt="LASIEC" />
                </Link>
                <Link to="/home" className="nav__brand--lasiec">
                    <img src={lasiec} alt="LASIEC" />
                </Link>
                <div className="nav__brand--text">
                  <h1>lasiec</h1>
                  <p className="first">Lagos state</p>
                  <p className="second">Independent Electoral Commission</p>
                </div>
            </div>
            <div className="nav__toggler-icon" onClick={props.clicked} >
             <MenuIcon />
            </div>
            <div className="nav__navigation">
                {
                    props.isAuthenticated ? <ul className="nav__navigation--menu__admin">
                    <li> ({ props.role}) - <span>Welcome: </span> { props.username } </li>
                    <li><Link to="/admin/logout">Logout</Link></li>
                </ul>
                :
                null
                }
                
            </div>
        </div>
        </nav>
    );
  
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.token !== null,
    username: state.auth.username,
    role: state.auth.role
})

export default connect(mapStateToProps)(NavMenu)
