import * as actionTypes from './../Actions/ActionTypes/ActionTypes'

const initialState = {
    count: 0,
    connection: null,
    user: { 
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
    },
    messages: [],
    username: null,
    message: '',
    queue: null,
    isTyping: false,
    agentName: null,
    ConnecteduserList: []
}


export default (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.registerUser:
            return {
                ...state,
                user: {
                    ...action.user
                }
            }

        case actionTypes.setConnection:
            return {
                ...state,
                connection: action.connection
            }
        case actionTypes.setQueueInterval:
            return {
                ...state,
                queue: action.queue
            }
        case actionTypes.setRegisteredUser:
            return {
                ...state,
                username: action.username
            }
        case actionTypes.setCount:
            return {
                ...state,
               count: action.count
            }
        case actionTypes.setIsTyping:
            return {
                ...state,
                isTyping: action.typing
            }
        case actionTypes.setAgentName:
            return {
                ...state,
                agentName: action.agentName
            }
    
        default:
            return state
    }
}