import * as actionTypes from '../ActionTypes/ActionTypes'
import axios from 'axios'

export const addChairmanship = entity => ({
	type: actionTypes.addChairmanship,
	entity,
})
export const addCounsellorship = entity => ({
	type: actionTypes.addCounsellorship,
	entity,
})

export const declareWinner = entity => ({
	type: actionTypes.winnerChairmain,
	entity,
})
export const declareWinnerCounsellorship = entity => ({
	type: actionTypes.winnerCounsellor,
	entity,
})
export const removeChairman = entity => ({
	type: actionTypes.removeChairmain,
	entity,
})
export const removeCounsellor = entity => ({
	type: actionTypes.removeCounsellor,
	entity,
})

export const setLgId = lgId => {
	return {
		type: actionTypes.setLgId,
		lgId
	}
}
export const addPublish = id => {
	return {
		type: actionTypes.AddPublish,
		id
	}
}
export const removePublish = id => {
	return {
		type: actionTypes.RemovePublish,
		id
	}
}

export const setSingleChairman = entity => {
	return {
		type: actionTypes.getSingleChairman,
		entity
	}
}
export const setSingleCounsellor = entity => {
	return {
		type: actionTypes.getSingleCounsellor,
		entity
	}
}
export const addCounsellorPublish = id => {
	return {
		type: actionTypes.AddCounsellorPublish,
		id
	}
}
export const removeCounsellorPublish = id => {
	return {
		type: actionTypes.RemoveCounsellorPublish,
		id
	}
}

export const setChairmanship = entities => ({
	type: actionTypes.setChairmanship,
	entities,
})
export const setCounsellorship = entities => ({
	type: actionTypes.setCounsellorship,
	entities,
})

export const setCurrentLg = lg => ({
	type: actionTypes.setCurrentLg,
	lg,
})
export const setCurrentWard = ward => ({
	type: actionTypes.setCurrentWard,
	ward,
})

export const editChairmanship = entity => ({
	type: actionTypes.editChairmanship,
	entity
})
export const editCounsellorship = entity => ({
	type: actionTypes.editCounsellorship,
	entity
})

export const success = () => {
	return {
		type: actionTypes.begin
	}
}
export const successStop = () => {
	return {
		type: actionTypes.end
	}
}

export const addScoreToChairman = (score) => {
	return {
		type: actionTypes.AddScoreToChairman,
		score
	}
}
export const addScoreToCounsellor = (score) => {
	return {
		type: actionTypes.AddScoreToCounsellor,
		score
	}
}

export const addChairmanshipAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/ChairmanshipElection/AddChairmanshipElection', entity, {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				dispatch(addChairmanship(response.data))
			

				setTimeout(() => {
					dispatch(successStop())
				}, 3000);
			})
			.catch(error => {})
	}
}

export const addCounsellorshipAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/CounsellorshipElection/AddCounsellorshipElection', entity, {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				dispatch(addCounsellorship(response.data))
				setTimeout(() => {
					dispatch(successStop())
				}, 3000);
			})
			.catch(error => {})
	}
}

export const setChairmanshipAsync = token => {
	return dispatch => {
	
		axios
			.get('/ChairmanshipElection/GetAllChairmanshipElections')
			.then(response => {
				dispatch(setChairmanship(response.data))
			})
			.catch(error => {})
	}
}
export const setChairmanshipPublicAsync = token => {
	return dispatch => {
	
		axios
			.get('/ChairmanshipElection/GetAllChairmanshipElectionsPublics')
			.then(response => {
				dispatch(setChairmanship(response.data))
			})
			.catch(error => {})
	}
}
export const getSingleCharimanAsync = (id, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.get('/ChairmanshipElection/GetChairman/' + id, {
				headers: {
					Authorization: auth,
				}
			})
			.then(response => {
				dispatch(setSingleChairman(response.data))
			})
			.catch(error => {})
	}
}
export const getSingleCouncillorAsync = (id, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.get('/CounsellorshipElection/GetCounsellor/' + id, {
				headers: {
					Authorization: auth,
				}
			})
			.then(response => {
				dispatch(setSingleCounsellor(response.data))
			})
			.catch(error => {})
	}
}
export const setCounsellorshipAsync = token => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.get('/CounsellorshipElection/GetAllCounsellorshipElections', {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(setCounsellorship(response.data))
			})
			.catch(error => {})
	}
}
export const setCounsellorshipPublicAsync = token => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.get('/CounsellorshipElection/GetAllCounsellorshipElectionsPublics', {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(setCounsellorship(response.data))
			})
			.catch(error => {})
	}
}

export const editChairmanshipAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/ChairmanshipElection/UpdateChairman', entity,  {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				dispatch(editChairmanship(response.data.man))
				setTimeout(() => {
					dispatch(successStop())
				}, 3000);
				
			})
			.catch(error => {})
	}
}
export const editCounsellorshipAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/CounsellorshipElection/UpdateCounsellor', entity,  {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				dispatch(editCounsellorship(response.data.man))
				setTimeout(() => {
					dispatch(successStop())
				}, 3000);
			})
			.catch(error => {})
	}
}

export const addScoreToChairmanshipAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/ElectionRecord/AddElectionRecord', entity,  {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				dispatch(addScoreToChairman(response.data))
				setTimeout(() => {
					dispatch(successStop())
				}, 3000);
				
			})
			.catch(error => {})
	}
}

export const addScoreToCounsellorshipAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/ElectionRecordCounsellor/AddElectionRecordCounsellor', entity,  {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				dispatch(addScoreToCounsellor(response.data))
				setTimeout(() => {
					dispatch(successStop())
				}, 3000);
				
			})
			.catch(error => {})
	}
}

export const DeclareWinnerChairmanshipAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/ChairmanshipElection/DeclareWinner', entity,  {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				dispatch(declareWinner(response.data))
				setTimeout(() => {
					dispatch(successStop())
				}, 3000);
				
			})
			.catch(error => {})
	}
}
export const DeclareWinnerCounsellorAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/CounsellorshipElection/DeclareWinner', entity,  {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				dispatch(declareWinnerCounsellorship(response.data))
				setTimeout(() => {
					dispatch(successStop())
				}, 3000);
				
			})
			.catch(error => {})
	}
}

export const publishScoreChairmanshipAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/ElectionRecord/PublishElectionRecord', entity,  {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				setTimeout(() => {
					dispatch(successStop())
					dispatch(setChairmanshipAsync())
				}, 3000);
				
			})
			.catch(error => {
			})
	}
}

export const publishScoreCounsellorshipAsync = (entity, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.post('/ElectionRecordCounsellor/PublishElectionRecordCounsellor', entity,  {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				setTimeout(() => {
					dispatch(successStop())
					dispatch(setCounsellorshipAsync())
				}, 3000);
				
			})
			.catch(error => {})
	}
}
export const deleteChairmanshipshipAsync = (id, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.delete('/ChairmanshipElection/RemoveChairman/'+ id,  {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				dispatch(removeChairman(response.data.eo))
				dispatch(successStop())
				
			})
			.catch(error => {})
	}
}
export const deleteCounsellorshipshipAsync = (id, token) => {
	return dispatch => {
		const auth = `Bearer ${token}`
		axios
			.delete('/CounsellorshipElection/RemoveCounsellor/'+ id,  {
				headers: {
					Authorization: auth,
				},
			})
			.then(response => {
				dispatch(success())
				dispatch(removeCounsellor(response.data.eo))
				dispatch(successStop())
				
			})
			.catch(error => {})
	}
}
